import Promise from 'promise-polyfill';

if (!window.Promise) {
  window.Promise = Promise;
}

import { h, render } from 'preact';
import { IntlProvider } from 'preact-i18n';
import './style';
import './assets/js/raphael-nosensor.min';
const definition = require(`./i18n/${process.env.LANGUAGE_FILE}`);
import configService from './services/config';
import logoutService from './services/logout';
import refreshTokenService from './services/refreshToken';
import getUserService from './services/getUser';
import loginService from './services/login';
import loginByCookieService from './services/authWithCookie';
import checkShortSessionService from './services/checkShortSession';
import dataVisorService from './services/dataVisor';
import { getCookieValue } from './helpers';
import authToken from './helpers/authToken';
import authWithCookie from './helpers/authWithCookie';
import authWithSocial from './helpers/authWithSocial';
import nativeAppCommunicator from './helpers/nativeAppCommunicator';

let root, clientId, apiKey, redirectUrl;

function ensureConfigData() {
  if (clientId && apiKey) {
    return Promise.resolve();
  }

  return refreshConfigData();
}

function refreshConfigData() {
  return configService().then((data) => {
    if (data && data.id) {
      clientId = data.id;
      apiKey = data.apiKey;
      redirectUrl = data.redirectUrl;
    }

    return data;
  });
}

/**
 * Initializes and mount the widget UI. This is the main function to call to integrate and load SSO Widget.
 *
 * @param {Object} config - The config object for initializing widget.
 * @param {string} config.elementRoot - The ID of the element to mount widget.
 * @param {boolean} [config.hideSignupTab] - True to hide sign up tab.
 * @param {string} [config.orientation] - Orientation of widget, horizontal or vertical.
 * @param {string} [config.lang] - The language we want to load the widget, e.g.: en-GB.
 * @param {function({
 *   accessToken: string
 *   refreshToken: string
 *   userId: string
 * })} [config.successCallback] - Executed when the widget is loaded and when the login is successful.
 * @param {function(Error)} [config.failureCallback] - Executed when the there is an error with login.
 */
function initialize(config) {
  const App = require('./components/app').default;

  return onload()
    .then(() => {
      config.clientId = clientId;
      config.apiKey = apiKey;
      config.redirectUrl = redirectUrl;

      if (!config.successCallback) {
        config.successCallback = () => {};
      }

      if (!config.failureCallback) {
        config.failureCallback = () => {};
      }
    })
    .then(() => authToken.get())
    .then((tokens) => {
      if (!config.elementRoot) {
        return true;
      }

      return new Promise((resolve) => {
        function hasRendered(e) {
          resolve(e);
          initialize._done = true;
        }

        root = render(
          <IntlProvider definition={definition}>
            <App config={config} renderCallback={hasRendered} tokens={tokens} />
          </IntlProvider>,
          document.getElementById(config.elementRoot),
          root
        );
      });
    });
}

function checkExternalAuth() {
  if (window.location.search) {
    const query = new URLSearchParams(window.location.search);
    const externalAuth = query.get('externalAuth');

    if (externalAuth) {
      let tokens;
      try {
        tokens = JSON.parse(atob(externalAuth));
      } catch (err) {
        console.log(err);
        console.log(`Unable to parse externalAuth of: ${externalAuth}`);
      }

      // as a security measurement remove externalAuth after consumption
      _removeExternalAuthFromPath(query);

      if (isValidExternalTokens(tokens)) {
        return authToken.set(tokens);
      }

      // Checks for {accessToken = '', refreshToken = '', userId: ''}, and ensure session is cleared.
      if (isEmptyExternalTokens(tokens)) {
        return (
          authToken
            .get()
            // use _logout instead to prevent native communicator
            .then((tokens) => _logout(tokens.accessToken))
            // catch to prevent failure since cookie/tokens may already been cleared.
            .catch((err) => console.log(err))
        );
      }
    }
  }
}

function isValidExternalTokens(tokens) {
  return (
    tokens &&
    ['accessToken', 'refreshToken', 'userId'].every((key) => tokens[key])
  );
}

function isEmptyExternalTokens(tokens) {
  return (
    tokens &&
    ['accessToken', 'refreshToken', 'userId'].every((key) => tokens[key] === '')
  );
}

function authorizationByCredentials(username, password) {
  return ensureConfigData()
    .then(() => dataVisorService.getToken())
    .then((dvToken) =>
      loginService(username, password, dvToken, clientId, apiKey)
    )
    .then((response) => {
      if (response.status === 200) {
        return authToken
          .set(response.data)
          .then(() => nativeAppCommunicator.send(response.data, 'login'))
          .then(() => response);
      }

      return response;
    });
}

/**
 * @deprecated
 */
function authorizationByCookie() {
  return ensureConfigData()
    .then(() => loginByCookieService(clientId, apiKey))
    .then((tokens) => authToken.set(tokens))
    .catch(() => refreshToken())
    .then(() => authToken.get());
}

/**
 * Handles the social login authentication flow, which includes updating the necessary tokens,
 * native app communications and sending of GTM data.
 *
 * @param {string} networkType - The plaform of the social login intended
 * @param {object} config - The configuration needed for social login
 * @returns {Promise} Promise object which represents the authentication and user information
 */
function authorizationBySocial(networkType, config) {
  return authWithSocial(networkType, config);
}

function config() {
  return configService();
}

function logout() {
  return authToken
    .get()
    .then((tokens) => {
      if (!tokens.accessToken && !tokens.refreshToken) {
        return _cleanUp();
      }

      return _logout(tokens.accessToken);
    })
    .then(() => {
      // Send empty tokens to mobile
      const invalidTokens = {
        accessToken: '',
        refreshToken: '',
        userId: '',
      };

      return nativeAppCommunicator.send(invalidTokens, 'logout');
    });
}

function _removeExternalAuthFromPath(query) {
  const { protocol, host, pathname } = location;
  query.delete('externalAuth');

  let search = query.toString();

  if (search) {
    search = `?${search}`;
  }

  history.replaceState('', '', `${protocol}//${host}${pathname}${search}`);
}

function _logout(accessToken) {
  const gaClientId = getCookieValue(process.env.GA_CLIENT_ID);
  return ensureConfigData()
    .then(() => logoutService(clientId, apiKey, accessToken, gaClientId))
    .then((response) => {
      if (response.ok) {
        return true;
      }

      if (response && response.code === 'INVALID_ACCESS_TOKEN') {
        // Invalid access token from SSO (auth)
        return refreshToken().then((response) => {
          if (response && response.accessToken) {
            return logoutService(
              clientId,
              apiKey,
              response.accessToken,
              gaClientId
            );
          }
          return false;
        });
      }

      if (response && response.code === 'NOT_AUTHORIZED') {
        // Invalid session from SSO
        // Call logout again but with /auth version of logout instead
        return logoutService(clientId, apiKey, accessToken, gaClientId, false);
      }

      return false;
    })
    .then((ok) => _cleanUp() && ok);
}

function _cleanUp() {
  return authToken.del().then(() => {
    const gtmDataLayer = window.gtmDataLayer || [];
    gtmDataLayer.push({
      event: 'logout',
      memberId: null,
    });

    return true;
  });
}

function refreshToken() {
  let tokens;

  return ensureConfigData()
    .then(() => authToken.get())
    .then((value) => {
      tokens = value;
      return refreshTokenService(clientId, apiKey, tokens.refreshToken);
    })
    .then((response) => {
      if (!response || !response.accessToken) {
        return Promise.reject(new Error('No access token found in response.'));
      }

      // Pass response to the next chain to return the response from refreshTokenService
      return Promise.all([
        authToken.set({
          ...tokens,
          ...response,
        }),
        response,
      ]);
    })
    .then((responses) => responses[1]);
}

function getUser() {
  let tokens, gaClientId;

  return ensureConfigData()
    .then(() => authToken.get())
    .then((value) => {
      tokens = value;
      gaClientId = getCookieValue(process.env.GA_CLIENT_ID);
      return getUserService(
        tokens.userId,
        tokens.accessToken,
        clientId,
        apiKey,
        gaClientId
      );
    })
    .catch(() => refreshTokenService(clientId, apiKey, tokens.refreshToken))
    .then((response) => {
      if (response && response.accessToken) {
        return getUserService(
          tokens.userId,
          response.accessToken,
          clientId,
          apiKey,
          gaClientId
        );
      }

      return response;
    });
}

function checkShortSession() {
  let tokens;

  return ensureConfigData()
    .then(() => authToken.get())
    .then((value) => {
      tokens = value;
      return checkShortSessionService(tokens.accessToken, clientId, apiKey);
    })
    .catch(() => refreshTokenService(clientId, apiKey, tokens.refreshToken))
    .then((response) => {
      if (response && response.accessToken) {
        return checkShortSessionService(response.accessToken, clientId, apiKey);
      }

      return response;
    });
}

/**
 * Returns promise that resolves to an object.
 * Object will contain accessToken, refreshToken and userId if user is logged in.
 *
 * @return {Promise<{
 *   accessToken?: string
 *   refreshToken?: string
 *   userId?: string
 * }>}
 */
function getTokens() {
  return authToken.get();
}

/**
 * Returns promise that resolves to boolean.
 * True if access token exist, this indicates user is logged in, false otherwise.
 *
 * @return {Promise<boolean>}
 */
function isLoggedIn() {
  return authToken.get().then((tokens) => Boolean(tokens.accessToken));
}

// in development, set up HMR:
if (module.hot) {
  //require('preact/devtools');   // turn this on if you want to enable React DevTools!
  module.hot.accept('./components/app', () =>
    requestAnimationFrame(initialize)
  );
}

/**
 * Initialize authentication related data when the script is injected but aaWidget.initialize was not called.
 * This ensures that authentication is still processed even if the widget UI is not mounted.
 */
function onload() {
  // Prevent racing condition with initialize, and to ensure onload will always only called once.
  if (onload._process) {
    return onload._process;
  }

  onload._process = refreshConfigData()
    .then(() => checkExternalAuth())
    .then(() => refreshToken())
    .catch((err) => {
      if (err.status && err.status >= 500) {
        return Promise.reject(err);
      }

      return authWithCookie(clientId, apiKey);
    })
    .catch((err) => {
      console.log(err);

      if (err.status && err.status >= 500) {
        // Avoid deleting token on 5xx error cases
        return;
      }

      return authToken.del();
    });

  return onload._process;
}

onload();

window.aaWidget = {
  initialize,
  authorizationByCredentials,
  authorizationByCookie,
  authorizationBySocial,
  config,
  logout,
  refreshToken,
  getUser,
  getTokens,
  isLoggedIn,
  checkShortSession,
};
