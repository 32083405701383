import fetch from 'unfetch';

/**
 * `params` contains email, dialingCode and phoneNumber.
 */
export default (clientId, apiKey, params) =>
  fetch(`${process.env.API_GW_URL}/um/v2/users/otp`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      'x-aa-client-id': clientId,
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return null;
    }
    return response.json().then((data) => {
      return {
        status: response.status,
        data,
      };
    });
  });
