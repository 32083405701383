import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import { getLanguage } from '../../helpers';

import Errors from '../errors';
import validator from '../../helpers/validators';
import authToken from '../../helpers/authToken';
import TextField from '../common/textField';
import PasswordField from '../common/passwordField';

import loginService from '../../services/login';
import resendActivationLinkService from '../../services/resendActivationLink';
import className from 'classnames';
import nativeAppCommunicator from '../../helpers/nativeAppCommunicator';

export default class Login extends Component {
  state = {
    username: '',
    password: '',
    error: '',
    usernameError: false,
    passwordError: false,
    isErrorFocused: false,
    isActivationLinkRequired: false,
    isResetLinkRequired: false,
    isResetPasswordRequired: false,
    isSignupLinkRequired: false,
    isErrorNotify: false,
    disableResentLink: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ error: nextProps.error });
    }
  }

  handleChangeUsername = (username) => {
    this.setState({ username: username.toLowerCase() });
  };

  handleChangePassword = (password) => {
    this.setState({ password });
  };

  handleErrorMessageBlur = () => {
    this.setState({ isErrorFocused: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      error: '',
      usernameError: false,
      passwordError: false,
      isErrorFocused: false,
      isActivationLinkRequired: false,
      isResetLinkRequired: false,
      isResetPasswordRequired: false,
      isSignupLinkRequired: false,
      disableResentLink: false,
    });
    const { username, password } = this.state;

    if (!validator.isEmail(username)) {
      this.setState({
        error: 'errors.invalidEmail',
        usernameError: true,
        isErrorFocused: true,
      });
      return;
    }

    if (!validator.isPassword(password)) {
      this.setState({
        error: 'errors.incorrectPassword',
        passwordError: true,
        isErrorFocused: true,
        isResetLinkRequired: true,
      });
      // Example for "Email not found" error when link goes to signup tab with prefilled email
      // this.setState({error: 'errors.noEmail', passwordError: true, isErrorFocused: true, isSignupLinkRequired: true});
      return;
    }

    loginService(
      username,
      password,
      this.props.dataVisorToken,
      this.props.clientId,
      this.props.apiKey,
      this.props.gaClientId
    )
      .then((response) => {
        const data = response.data;
        // eslint-disable-next-line eqeqeq
        if (response.status == 202) {
          const userData = Object.assign(data || {}, {
            username,
            password,
            clientId: this.props.clientId,
            gaClientId: this.props.gaClientId,
          });
          this.props.on2FANeeded(userData);
        } else if (data && data.code) {
          switch (data.code) {
            case 'USER_NOT_ACTIVATED':
              this.setState({
                error: 'errors.userNotActivated',
                isActivationLinkRequired: true,
                isErrorFocused: true,
                isResetLinkRequired: false,
                isErrorNotify: false,
                isResetPasswordRequired: false,
                isCustomerSupportInactive: false,
                isCustomerSupportLocked: false,
              });
              break;
            case 'USER_INACTIVE':
              this.setState({
                error: 'errors.userInactive',
                isErrorFocused: true,
                isCustomerSupportInactive: true,
                isResetLinkRequired: false,
                isErrorNotify: false,
                isResetPasswordRequired: false,
                isCustomerSupportLocked: false,
              });
              break;
            case 'USER_LOCKED':
              this.setState({
                error: 'errors.userLocked',
                isErrorFocused: true,
                isCustomerSupportLocked: true,
                isResetLinkRequired: false,
                isErrorNotify: false,
                isResetPasswordRequired: false,
                isCustomerSupportInactive: false,
              });
              break;
            case 'USER_TERMINATED':
              if (data.failedLoginAttempt < 10) {
                // USER TERMINATED BY CHOICE
                this.setState({
                  error: 'errors.userForgotten',
                  isErrorFocused: true,
                  isActivationLinkRequired: true,
                  isResetLinkRequired: false,
                  isErrorNotify: false,
                  isResetPasswordRequired: false,
                  isCustomerSupportInactive: false,
                  isCustomerSupportLocked: false,
                });
              } else {
                this.setState({
                  error: 'errors.userTerminated',
                  isErrorFocused: true,
                  isResetPasswordRequired: true,
                  isErrorNotify: false,
                  isResetLinkRequired: false,
                  isActivationLinkRequired: false,
                  isCustomerSupportInactive: false,
                  isCustomerSupportLocked: false,
                });
              }
              break;
            default:
              // WRONG PASSWORD / USERNAME
              if (data.failedLoginAttempt === 10 && data.status === 'active') {
                // USER TERMINATED AT FLA 10
                this.setState({
                  error: 'errors.userTerminated',
                  isErrorFocused: true,
                  isResetLinkRequired: false,
                  isResetPasswordRequired: true,
                  isErrorNotify: true,
                  isActivationLinkRequired: false,
                  isCustomerSupportInactive: false,
                  isCustomerSupportLocked: false,
                });
              } else if (
                data.failedLoginAttempt < 10 &&
                data.status === 'terminated'
              ) {
                // USER TERMINATED BY CHOICE
                this.setState({
                  error: 'errors.userForgotten',
                  isErrorFocused: true,
                  isActivationLinkRequired: true,
                  isErrorNotify: false,
                  isResetPasswordRequired: false,
                  isResetLinkRequired: false,
                  isCustomerSupportInactive: false,
                  isCustomerSupportLocked: false,
                });
              } else if (data.status === 'inactive') {
                this.setState({
                  error: 'errors.userInactive',
                  isErrorFocused: true,
                  isCustomerSupportInactive: true,
                  isActivationLinkRequired: false,
                  isErrorNotify: false,
                  isResetPasswordRequired: false,
                  isResetLinkRequired: false,
                  isCustomerSupportLocked: false,
                });
              } else if (data.status === 'locked') {
                this.setState({
                  error: 'errors.userLocked',
                  isErrorFocused: true,
                  isCustomerSupportLocked: true,
                  isActivationLinkRequired: false,
                  isErrorNotify: false,
                  isResetPasswordRequired: false,
                  isResetLinkRequired: false,
                  isCustomerSupportInactive: false,
                });
              } else if (data.failedLoginAttempt % 3 === 0) {
                this.props.onForgotPassword({ username });
              } else {
                this.setState({
                  error: 'errors.invalidEmailOrPassword',
                  passwordError: true,
                  usernameError: true,
                  isErrorFocused: true,
                  isResetLinkRequired: false,
                  isResetPasswordRequired: false,
                  isActivationLinkRequired: false,
                  isErrorNotify: false,
                  isCustomerSupportInactive: false,
                  isCustomerSupportLocked: false,
                });
              }
          }
          this.props.onFailure(data.code);
        } else if (data && data.updateForm === true) {
          this.props.onUpdateForm(data);
        } else {
          return authToken
            .set(data)
            .then(() => nativeAppCommunicator.send(data, 'login'))
            .then(() => this.props.onSuccess(data));
        }
      })
      .catch((e) => {
        this.props.onFailure('CONNECTION_ERROR');
        console.error(e);
        this.setState({
          error: 'errors.invalidEmailOrPassword',
          passwordError: true,
          usernameError: true,
          isErrorFocused: true,
          isResetLinkRequired: false,
          isResetPasswordRequired: false,
          isErrorNotify: false,
        });
      });
  };

  resendActivationLink = (e) => {
    e.preventDefault();
    if (!this.state.disableResentLink) {
      const language = getLanguage();
      const username = this.state.username;

      resendActivationLinkService(
        username,
        language,
        this.props.clientId,
        this.props.apiKey
      ).catch((e) => {
        console.error(e);
      });

      this.setState({ disableResentLink: true });
    }
  };

  render(props, state) {
    let linkAction = null;
    if (state.isResetLinkRequired || state.isResetPasswordRequired) {
      linkAction = props.onForgotPassword;
    } else if (state.isSignupLinkRequired) {
      linkAction = props.onSwitchToSignup;
    }
    return (
      <div
        id="aaw-login-tab"
        className={className({
          'aaw-tab-container--vertical': props.hasSocialLogin,
        })}
        role="tabpanel"
        aria-labelledby="aaw-login"
      >
        <form onSubmit={this.handleSubmit}>
          <Errors
            error={state.error}
            isFocused={state.isErrorFocused}
            email={this.state.username}
            onBlur={this.handleErrorMessageBlur}
            resetLink={state.isResetLinkRequired}
            isTerminated={state.isResetPasswordRequired}
            isTerminatedNotify={state.isErrorNotify}
            activationLink={state.isActivationLinkRequired}
            customerSupportLogin={state.isCustomerSupportInactive}
            customerSupportLocked={state.isCustomerSupportLocked}
            linkAction={linkAction}
            linkFunction={this.resendActivationLink}
          />
          {/* Example for error when link goes to signup tab with prefilled email */}
          {/* <Errors error={state.error} isFocused={state.isErrorFocused} email={this.state.username} onBlur={this.handleErrorMessageBlur} signupLink={state.isSignupLinkRequired} linkAction={linkAction} /> */}
          <div
            className={className({
              'aaw-row': props.isHorizontal && !props.hasSocialLogin,
            })}
          >
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6':
                  props.isHorizontal && !props.hasSocialLogin,
              })}
            >
              <TextField
                textLabel={<Text id="email">Email</Text>}
                error={state.usernameError}
                tab="login"
                maxLength={64}
                onValueChange={this.handleChangeUsername}
              />
            </div>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6':
                  props.isHorizontal && !props.hasSocialLogin,
              })}
            >
              <PasswordField
                error={state.passwordError}
                tab="login"
                onPasswordChange={this.handleChangePassword}
                maxLength={16}
              />
            </div>
          </div>
          <div
            className={className({
              'aaw-row aaw-align--vertical':
                props.isHorizontal && !props.hasSocialLogin,
            })}
          >
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m8':
                  props.isHorizontal && !props.hasSocialLogin,
                'aaw-top-padding': !props.isHorizontal || props.hasSocialLogin,
              })}
            >
              <Localizer>
                <a
                  href="javascript:"
                  id="forgotPasswordLink"
                  name="forgot-password"
                  onClick={props.onForgotPassword}
                  aria-label={
                    <Text id="loginTab.forgotPassword">Forgot password?</Text>
                  }
                >
                  <Text id="loginTab.forgotPassword">Forgot password?</Text>
                </a>
              </Localizer>
            </div>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m4 aaw-el--top-padding':
                  props.isHorizontal && !props.hasSocialLogin,
                'aaw-top-padding': !props.isHorizontal || props.hasSocialLogin,
              })}
            >
              <Localizer>
                <input
                  type="submit"
                  id="loginbutton"
                  className="aaw-button"
                  name="login"
                  value={<Text id="loginTab.login">Log in</Text>}
                  role="button"
                />
              </Localizer>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
