/**
 * Helper to send data from webpage loaded in webview
 * to native app.
 *
 * @module NativeAppCommunicator
 */

/**
 * Sends data to particular native handlers in base64
 * string format.
 *
 * @param {object} data A javascript object
 * @param {string} [event] Event type, for mobile to identify actions, currently login/logout
 */
function send(data, event) {
  const handlers = _getNativeHandlers();
  const payload = {
    ...data,
    type: event,
  };
  const encodedPayload = btoa(JSON.stringify(payload));

  console.group('native-token-handoff');
  console.log(payload, encodedPayload);
  console.groupEnd();

  Object.keys(handlers).forEach((handlerName) => {
    handlers[handlerName](encodedPayload);
  });
}

function _getNativeHandlers() {
  const defaultHandler = () => {};
  const ios =
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.ssoMessageHandler &&
    window.webkit.messageHandlers.ssoMessageHandler.postMessage;
  const android =
    window.ssoMessageHandler && window.ssoMessageHandler.postMessage;
  return {
    ios: ios
      ? function (data) {
          return window.webkit.messageHandlers.ssoMessageHandler.postMessage(
            data
          );
        }
      : defaultHandler,
    android: android
      ? function (data) {
          return window.ssoMessageHandler.postMessage(data);
        }
      : defaultHandler,
  };
}

export default {
  send,
};
