import fetch from 'unfetch';

/**
 * @deprecated Not needed anymore, since login is using localstorage, as this is only for server side to set login cookie.
 */
export default (tokens, apiKey) =>
  fetch(
    `${process.env.API_GW_URL}/sso/v2/authorization/social/wechat/callback?accessToken=${tokens.accessToken}&refreshToken=${tokens.refreshToken}&userId=${tokens.userId}&type=signup`,
    {
      method: 'get',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    }
  ).then((response) => {
    return response.json();
  });
