import fetch from 'unfetch';

export default (params, apiKey) =>
  fetch(`${process.env.API_GW_URL}/um/v2/users`, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      'x-aa-client-id': params.clientId,
      'x-ga-client-id': params.gaClientId,
    },
    body: JSON.stringify(params),
  }).then((response) => {
    return response.json();
  });
