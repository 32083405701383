import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import className from 'classnames';

import Errors from '../errors';
import validator from '../../helpers/validators';
import { getLanguage } from '../../helpers';

import TextField from '../common/textField';

import forgotPasswordService from '../../services/forgotPassword';
import resendActivationLinkService from '../../services/resendActivationLink';

export default class ForgotPassword extends Component {
  state = {
    username: '',
    error: '',
    usernameError: false,
    submitted: false,
    isErrorFocused: false,
    isProcessing: false,
    isActivationLinkRequired: false,
    disableResentLink: false,
  };

  handleChangeUsername = (username) => {
    if (username) {
      this.setState({ username: username.toLowerCase() });

      return username;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ error: '', usernameError: false });
    const { username, isProcessing } = this.state;
    if (!validator.isEmail(username)) {
      this.setState({
        error: 'errors.invalidEmail',
        usernameError: true,
        isErrorFocused: true,
      });
      return;
    }
    const language = getLanguage();
    if (!isProcessing) {
      console.log('SUBMIT RESET PASSWORD');
      this.setState({ isProcessing: true });
      forgotPasswordService(
        username,
        language,
        this.props.clientId,
        this.props.apiKey
      )
        .then(() => {
          this.setState({ submitted: true, isProcessing: false });
        })
        .catch((err) => {
          const errorJson = {
            INVALID_USERNAME: 'emailNotFound',
            USER_TERMINATED: 'userForgotten',
            USER_LOCKED: 'userLocked',
            USER_INACTIVE: 'userInactiveRegister',
            USER_NEW: 'userNotActivated',
          };
          const errMsg = errorJson[err] ? errorJson[err] : 'serverError';
          if (errMsg == 'userNotActivated' || errMsg == 'userForgotten') {
            this.setState({ isActivationLinkRequired: true });
          } else if (errMsg == 'userInactiveRegister') {
            this.setState({ customerSupportRegister: true });
          } else if (errMsg == 'userLocked') {
            this.setState({ customerSupportLocked: true });
          }
          this.setState({
            error: `errors.${errMsg}`,
            usernameError: false,
            isErrorFocused: false,
            isProcessing: false,
          });
        });
    }
  };

  handleErrorMessageBlur = () => {
    this.setState({ isErrorFocused: false });
  };

  resendActivationLink = (e) => {
    e.preventDefault();
    if (!this.state.disableResentLink) {
      const language = getLanguage();
      const username = this.state.username;

      resendActivationLinkService(
        username,
        language,
        this.props.clientId,
        this.props.apiKey
      ).catch((e) => {
        console.error(e);
      });

      this.setState({ disableResentLink: true });
      setTimeout(
        () => this.setState({ disableResentLink: false }),
        parseInt(process.env.RESEND_ACTIVATION_LINK_TIMEOUT, 10)
      );
    }
  };

  renderForgotPassword(props) {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="aaw-title">
          <Text id="forgotPassword.forgotPassword">Forgot password?</Text>
        </div>
        <span role="alert" aria-live="assertive">
          <Text id="forgotPassword.enterEmail">
            It happens to all of us. Just enter the email you registered with
            and we'll email the details to reset your password.
          </Text>
        </span>
        <Errors
          error={this.state.error}
          isFocused={this.state.isErrorFocused}
          onBlur={this.handleErrorMessageBlur}
          activationLink={this.state.isActivationLinkRequired}
          customerSupportRegister={this.state.customerSupportRegister}
          customerSupportLocked={this.state.customerSupportLocked}
          disableActivationLink={this.state.disableResentLink}
          linkFunction={this.resendActivationLink}
        />
        <div className={className({ 'aaw-row': props.isHorizontal })}>
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
              'aaw-input-padding--vertical': !props.isHorizontal,
            })}
          >
            <TextField
              textLabel={<Text id="email">Email</Text>}
              onBlur={this.handleChangeUsername}
              defaultValue={props.username}
              error={this.state.usernameError}
              tab="forgotPassword"
              onValueChange={this.handleChangeUsername}
              focusOnMount={true}
            />
          </div>
          <div
            className={className('aaw-input-field', {
              'aaw-col aaw-s12 aaw-m6 ': props.isHorizontal,
            })}
          >
            <Localizer>
              <input
                type="submit"
                id="sendResetLinkButton"
                className="aaw-button"
                name="send_reset_link"
                value={
                  <Text id="forgotPassword.sendResetLink">
                    Send password reset email
                  </Text>
                }
              />
            </Localizer>
          </div>
        </div>
        <div
          className={className({
            'aaw-link-pading--vertical': !props.isHorizontal,
          })}
        >
          <a href="javascript:" id="backToLoginLink" onClick={props.onCancel}>
            <span className="aaw-left-arrow">&lsaquo;</span>
            &nbsp;&nbsp;
            <Text id="forgotPassword.backToLogin">Back to login</Text>
          </a>
        </div>
      </form>
    );
  }

  renderCheckEmail(props) {
    return (
      <div>
        <div className="aaw-title">
          <Text id="forgotPassword.checkYourEmail">
            Please check your email
          </Text>
        </div>
        <div role="alert" aria-live="assertive">
          <span>
            <Text id="forgotPassword.linkToResetYourPassword">
              An email to reset your password has been sent to
            </Text>
          </span>
          <div className="aaw-email aaw-valign-wrapper">
            <i className="aaw-done" /> &nbsp;&nbsp;{this.state.username}
          </div>
        </div>
        <span>
          <Text id="forgotPassword.kindlyClickLink">
            Kindly click the link in the email to reset your password.
          </Text>
        </span>
        <div className="aaw-back-to-login">
          <a href="javascript:" onClick={props.onCancel}>
            <span className="aaw-left-arrow">&lsaquo;</span>
            &nbsp;&nbsp;
            <Text id="forgotPassword.backToLogin">Back to login</Text>
          </a>
        </div>
      </div>
    );
  }

  render(props, state) {
    if (state.submitted) {
      return this.renderCheckEmail(props);
    }
    return this.renderForgotPassword(props);
  }
}
