import { h, Component } from 'preact';
import className from 'classnames';
import { Localizer, Text } from 'preact-i18n';

export default class PasswordField extends Component {
  state = {
    active: false,
    value: this.props.defaultValue || '',
    type: 'password',
  };

  onChange = (e) => {
    const value = e.target.value;
    this.setState((prevState, props) => {
      props.onPasswordChange(value);
      return { value };
    });
  };

  onFocus = () => {
    this.setState({ active: true });
  };

  onBlur = () => {
    if (!this.state.value) {
      this.setState({ active: false, type: 'password' });
    }
  };

  showPassword = () => {
    this.setState({
      type: this.state.type === 'password' ? 'text' : 'password',
    });
  };

  onKeyUp = (e) => {
    // Space or Enter arrow
    const charCode = e.which || e.keyCode;

    if (charCode === 32 || charCode === 13) {
      this.showPassword();
    }
  };

  onAnimationStart = ({ animationName }) => {
    if (animationName === 'onAutoFillStart') {
      this.onFocus();
    }
  };

  componentDidMount() {
    this.passwordInput.addEventListener(
      'animationstart',
      this.onAnimationStart,
      false
    );
  }

  componentWillUnmount() {
    if (this.passwordInput) {
      this.passwordInput.removeEventListener(
        'animationstart',
        this.onAnimationStart,
        false
      );
    }
  }

  render(props) {
    return (
      <div
        className={className('aaw-input-field aaw-form__input', {
          'aaw-input--error': props.error,
          'aaw-input-border--blue':
            this.state.type === 'text' && this.state.value !== '',
        })}
      >
        <input
          id={`password-input--${props.tab}`}
          value={this.state.value}
          type={this.state.type}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          ref={(el) => (this.passwordInput = el)}
          aria-describedby={props.error && 'aaw-error'}
          aria-invalid={props.error}
          maxLength={this.props.maxLength}
        />
        <label
          className={className({
            'aaw-active': this.state.active || this.state.value,
          })}
          htmlFor={`password-input--${props.tab}`}
          aria-describedby={`password-input--${props.tab}`}
          role="tooltip"
        >
          <Text id="password">Password</Text>
        </label>
        <Localizer>
          <span
            className={className('aaw-button--show-password', {
              'aaw-icon--visibility-off': this.state.type === 'password',
              'aaw-icon--visibility': this.state.type === 'text',
            })}
            id={`${this.props.tab}-showPasswordButton`}
            role="button"
            aria-pressed={String(this.state.type !== 'password')}
            aria-label={<Text id="showPassword">Show password</Text>}
            tabindex="0"
            onKeyUp={this.onKeyUp}
            title={<Text id="showPassword">Show password</Text>}
            onClick={this.showPassword}
          />
        </Localizer>
      </div>
    );
  }
}
