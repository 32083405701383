import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import className from 'classnames';
import phone from 'phone';

import { getLanguage, getEmailProvider } from '../../helpers';
import validator from '../../helpers/validators';

import TextField from '../common/textField';
import MobileNumberField from '../common/mobileNumber';
import OTPQuotaReachError from '../common/otpQuotaReachError';
import signupService from '../../services/signup';
import resendActivationLinkService from '../../services/resendActivationLink';
import countryService from '../../services/getCountries';
import generateOTPService from '../../services/generateOTP';
import verifyOTPService from '../../services/verifyOTP';
import loginService from '../../services/login';
import socialOTPService from '../../services/socialOTP';
import Errors from '../errors';
import authToken from '../../helpers/authToken';
import nativeAppCommunicator from '../../helpers/nativeAppCommunicator';

const emailProviders = require('./../../helpers/emailProviders.json');
const STORAGE_KEY_OTP = 'aa_otp';
const OTP_COOL_DOWN_INTERVAL = 60; // Wait 60s to re-generate OTP.

export default class AdditionalFields extends Component {
  state = {
    gender: 'male',
    firstName: '',
    lastName: '',
    dialingCode: '60',
    phoneNumber: '',
    otp: '',
    submitted: false,
    hideEmailButton: false,
    disableResentLink: false,
    disableSubmitButton: false,
    disableMaybeLink: false,
    error: '',
    firstNameError: false,
    lastNameError: false,
    phoneNumberError: false,
    otpError: false,
    attemptsLeft: undefined, // To render error message. Will show if has positive value. Only relevant when otpError is true.
    isProcessing: false,
    countries: [],
    otpCountDown: 60,
    otpIntervalId: null,
    secondsLeft: null,
    page: 1, // Page 1 is info collection, page 2 is OTP generation, page 3 is OTP verification, page 4 is quota reached error.
  };

  constructor(props) {
    super(props);

    let mailPageUrl;
    if (this.props.userData.username) {
      mailPageUrl = getEmailProvider(
        this.props.userData.username,
        emailProviders
      );
    }
    if (!mailPageUrl) {
      this.setState({ hideEmailButton: true });
    }

    const dialingCode = this.props.userData.dialingCode;
    const mobilePhone = this.props.userData.mobilePhone;
    let phoneNumber = '';
    if (mobilePhone && mobilePhone.startsWith(dialingCode)) {
      phoneNumber = mobilePhone.replace(dialingCode, '');
    }
    this.setState({
      dialingCode: dialingCode || '60',
      phoneNumber,
      gender: this.props.userData.gender || 'male',
      firstName: this.props.userData.firstName || '',
      lastName: this.props.userData.lastName || '',
    });

    countryService(getLanguage()).then((response) => {
      this.setState({ countries: response });
    });
  }

  handleChangeGender = (e) => {
    this.setState({ gender: e.currentTarget.value });
  };

  handleChangeFirstName = (firstName) => {
    this.setState({ firstName });
  };

  handleChangeLastName = (lastName) => {
    this.setState({ lastName });
  };

  handleChangePhoneNumber = (dialingCode, phoneNumber) => {
    this.setState({ dialingCode, phoneNumber });
  };

  handleChangeOTP = (otp) => {
    this.setState({ otp });
  };

  handleSubmitPersonalInfo = (e) => {
    e.preventDefault();

    const { firstName, lastName } = this.state;

    let error = this._mobileInputError();

    if (!lastName) {
      this.setState({ error: 'errors.emptyLastName', lastNameError: true });
      error = true;
    } else if (!validator.isName(lastName)) {
      this.setState({
        error: 'errors.romanAlphabetsOnly',
        lastNameError: true,
      });
      error = true;
    } else {
      this.setState({ lastNameError: false });
    }

    if (!firstName) {
      this.setState({ error: 'errors.emptyGivenName', firstNameError: true });
      error = true;
    } else if (!validator.isName(firstName)) {
      this.setState({
        error: 'errors.romanAlphabetsOnly',
        firstNameError: true,
      });
      error = true;
    } else {
      this.setState({ firstNameError: false });
    }

    if (error) {
      return;
    }

    this.setState({ error: '' });

    // TODO(xiangxins): Check email uniqueness with server before going to next page to improve user experience.
    this.setState({ page: 2 });
  };

  handleRequestOTP = (e) => {
    e.preventDefault();
    const error = this._mobileInputError();
    if (!error) {
      this.setState({ error: '' });
      this.requestOTP();
    }
  };

  _mobileInputError = () => {
    let { dialingCode, phoneNumber } = this.state;
    let phoneNumberError = false;
    let error = '';
    let sanitizedPhoneNumber = null;

    if (
      !dialingCode ||
      !phoneNumber ||
      !validator.isValidDialingCode(dialingCode) ||
      !validator.isValidPhoneNumber(phoneNumber)
    ) {
      phoneNumberError = true;
      error = 'errors.emptyMobileNumber';
    } else {
      sanitizedPhoneNumber = this._sanitizePhoneNumber(
        dialingCode,
        phoneNumber
      );
      if (!sanitizedPhoneNumber) {
        console.error(
          `_mobileInputError: sanitization for number +${dialingCode}-${phoneNumber} failed.`
        );
        phoneNumberError = true;
        error = 'errors.invalidMobileNumber';
      }
    }

    let stateUpdate = {};

    if (sanitizedPhoneNumber) {
      stateUpdate.phoneNumber = sanitizedPhoneNumber;
    }

    if (
      phoneNumberError != this.state.phoneNumberError ||
      error != this.state.error
    ) {
      stateUpdate = { ...stateUpdate, phoneNumberError, error };
    }

    // only update state if there's real updates available- prevents
    // extra renders
    if (JSON.stringify(stateUpdate) !== '{}') {
      this.setState(stateUpdate);
    }

    return phoneNumberError;
  };

  /*
  This makes sure that any unwanted padding prefix like 0 is
  deleted as per the e.164 guideline for intl number.
  */
  _sanitizePhoneNumber(dialingCode, phoneNumber) {
    const sanitized = phone(`+${dialingCode}${phoneNumber}`);
    if (sanitized.length) {
      return sanitized[0].replace(`+${dialingCode}`, '');
    }
    return null;
  }

  submitSignupForm = (
    gender,
    firstName,
    lastName,
    dialingCode,
    phoneNumber
  ) => {
    if (!this.state.isProcessing) {
      this.setState({ isProcessing: true });
      const language = getLanguage();
      let params = {
        username: this.props.userData.username,
        password: this.props.userData.password,
        clientId: this.props.userData.clientId,
        gender,
        firstName,
        lastName,
        dialingCode,
        phoneNumber,
        sendEmail: true,
        cultureCode: language,
        notificationPreference: true,
        emailLanguageCode: language,
        gaClientId: this.props.userData.gaClientId,
        dataVisorToken: this.props.dataVisorToken,
      };

      for (let key in params) {
        if (params[key] === '') {
          delete params[key];
        }
      }

      signupService(params, this.props.apiKey)
        .then((response) => {
          if ('code' in response) {
            this.props.onError(response);
          } else {
            const gtmDataLayer = window.gtmDataLayer || [];
            gtmDataLayer.push({
              event: 'signupSubmit',
              signupMethod: 'email',
              email: this.props.userData.username,
            });
            this.setState({ submitted: true });
          }
        })
        .catch((e) => {
          this.setState({ isProcessing: false });
          console.error(e);
        });
    }
  };

  goToMailPage = (e) => {
    e.preventDefault();
    const mailPageUrl = getEmailProvider(
      this.props.userData.username,
      emailProviders
    );

    if (mailPageUrl !== '') {
      let win = window.open(mailPageUrl, '_blank');
      win.focus();
    }
  };

  goBack = () => {
    const oldPage = this.state.page;
    if (oldPage === 1) {
      this.props.navBack();
    } else {
      this.setState({ page: 1, error: false, otpError: false });
    }
  };

  resendActivationLink = (e) => {
    e.preventDefault();
    const language = getLanguage();
    const username = this.props.userData.username;

    resendActivationLinkService(
      username,
      language,
      this.props.clientId,
      this.props.apiKey
    ).catch((e) => {
      console.error(e);
    });

    this.setState({ disableResentLink: true });
    setTimeout(
      () => this.setState({ disableResentLink: false }),
      parseInt(process.env.RESEND_ACTIVATION_LINK_TIMEOUT, 10)
    );
  };

  _renderTitle(props, state) {
    let subtitle;
    let title;
    let description;
    if (state.page === 1) {
      if (props.renderGenderAndNameFields) {
        subtitle = (
          <p id="aaw-signup-subtitle">
            <Text id="signupTab.whatsYourName">
              Hi there! What’s your name?
            </Text>
          </p>
        );
        title = (
          <p id="aaw-signup-title">
            <Text id="signupTab.saveYourDetails">
              Save your details in your BIG Member account
            </Text>
          </p>
        );
      } else {
        subtitle = (
          <p id="aaw-signup-subtitle">
            <Text id="signupTab.hiThere">Hello!</Text>
          </p>
        );
        title = (
          <p id="aaw-signup-title">
            <Text id="signupTab.needVerification">
              Verify your mobile phone
            </Text>
          </p>
        );
        description = (
          <p id="aaw-signup-description">
            <Text id="signupTab.isItReallyYou">
              We want to confirm it’s you signing in.
            </Text>
          </p>
        );
      }
    } else {
      if (props.renderGenderAndNameFields) {
        subtitle = (
          <p id="aaw-signup-subtitle">
            <Text id="signupTab.isThatYou">Hello? Is that you?</Text>
          </p>
        );
      }
      title = (
        <p id="aaw-signup-title">
          <Text id="signupTab.verifyMobile">Verify your mobile phone</Text>
        </p>
      );
    }
    return (
      <div>
        <div>
          <div class="aaw-signup-page-indicator" />
          <div class="aaw-signup-page-indicator" />
          <div
            className={className('aaw-signup-page-indicator', {
              'aaw-signup-page-indicator-disabled': state.page === 1,
            })}
          />
        </div>
        <Errors error={state.error} attemptsLeft={state.attemptsLeft} />
        <div
          id="aaw-signup-title-container"
          aria-live="assertive"
          tabindex="0"
          aria-labelledby="aaw-signup-subtitle"
          aria-describedby="aaw-signup-title"
        >
          {subtitle}
          {title}
          {description}
        </div>
      </div>
    );
  }

  _renderSignUpPage1(props, state) {
    const button = (
      <input
        type="submit"
        className="aaw-button"
        name="save"
        value={<Text id="signupTab.continue">Continue</Text>}
        role="button"
      />
    );
    let buttonContainer;
    if (props.isHorizontal) {
      buttonContainer = (
        <div className={className('aaw-row aaw-align--vertical')}>
          <div
            className={className('aaw-col aaw-s12 aaw-m4 aaw-el--top-padding')}
          >
            <Localizer>{button}</Localizer>
          </div>
        </div>
      );
    } else {
      buttonContainer = (
        <div className={className('aaw-bottom-nav--vertical')}>
          <div className={className('aaw-top-padding')}>
            <Localizer>{button}</Localizer>
          </div>
        </div>
      );
    }
    return (
      <form onSubmit={this.handleSubmitPersonalInfo}>
        <div
          className={className('aaw-vertical-indent', {
            'aaw-row': props.isHorizontal,
          })}
        >
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6 aaw-el--top-padding': props.isHorizontal,
              'aaw-top-padding': !props.isHorizontal,
            })}
          >
            <input
              type="radio"
              name="gender"
              id="male"
              value="male"
              className="aaw-filled-in with-gap"
              onChange={this.handleChangeGender}
              checked={this.state.gender === 'male'}
            />
            <label htmlFor="male">
              <Text id="signupTab.male">Male</Text>
            </label>
            <input
              type="radio"
              name="gender"
              id="female"
              value="female"
              className="aaw-filled-in with-gap"
              onChange={this.handleChangeGender}
              checked={this.state.gender === 'female'}
            />
            <label htmlFor="female">
              <Text id="signupTab.female">Female</Text>
            </label>
          </div>
        </div>
        <div className={className({ 'aaw-row': props.isHorizontal })}>
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
            })}
          >
            <TextField
              textLabel={<Text id="signupTab.givenName">Given name</Text>}
              error={state.firstNameError}
              tab="additionalSignupFields-firstName"
              onValueChange={this.handleChangeFirstName}
              defaultValue={state.firstName}
              tooltipText={
                <Text id="tooltipText">
                  Enter name as per passport in roman alphabets (A-Z) only
                </Text>
              }
            />
          </div>
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
            })}
          >
            <TextField
              textLabel={
                <Text id="signupTab.familyName">Family name/Surname</Text>
              }
              error={state.lastNameError}
              tab="additionalSignupFields-lastName"
              onValueChange={this.handleChangeLastName}
              defaultValue={state.lastName}
              tooltipText={
                <Text id="tooltipText">
                  Enter name as per passport in roman alphabets (A-Z) only
                </Text>
              }
            />
          </div>
        </div>
        <div className={className({ 'aaw-row': props.isHorizontal })}>
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
            })}
          >
            <MobileNumberField
              listItems={this.state.countries}
              onValueChange={this.handleChangePhoneNumber}
              error={this.state.phoneNumberError}
              dialingCode={this.state.dialingCode}
              mobileNumber={this.state.phoneNumber}
            />
          </div>
        </div>
        {buttonContainer}
      </form>
    );
  }

  _renderSignInPage1(props, state) {
    return (
      <div>
        <p id="aaw-signup-text-get-otp" tabindex="0">
          <Text
            id="signupTab.getOTP"
            fields={{ number: this._displayNumber(state) }}
          >
            Get a One-Time Password (OTP) sent to your mobile phone{' '}
            {this._displayNumber(state)} for verification.
          </Text>
        </p>
        <form onSubmit={this.handleRequestOTP}>
          <div className={className({ 'aaw-row': props.isHorizontal })}>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
              })}
            >
              <MobileNumberField
                listItems={this.state.countries}
                onValueChange={this.handleChangePhoneNumber}
                error={this.state.phoneNumberError}
                dialingCode={this.state.dialingCode}
                mobileNumber={this.state.phoneNumber}
              />
            </div>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
              })}
            >
              <Localizer>
                <input
                  class="aaw-button aaw-signup-button-submit-otp"
                  type="submit"
                  value={<Text id="signupTab.requestOTP">Request OTP</Text>}
                  role="button"
                />
              </Localizer>
            </div>
          </div>
        </form>
      </div>
    );
  }

  _displayNumber(state) {
    const len = state.phoneNumber.length;
    const last4Digits = state.phoneNumber.slice(len - 4, len);
    let maskedPhoneNumber = '';
    for (let i = 0; i < len - 4; i++) {
      maskedPhoneNumber += 'x';
    }
    maskedPhoneNumber += last4Digits;
    return '+' + state.dialingCode + '-' + maskedPhoneNumber;
  }

  requestOTP = () => {
    if (this.state.otpIntervalId) {
      clearInterval(this.state.otpIntervalId);
    }
    let { dialingCode, phoneNumber } = this.state;

    const mobilePhone = dialingCode + phoneNumber;
    const now = Math.floor(Date.now() / 1000);
    const existingCache = this._otpCache();
    if (
      existingCache &&
      existingCache[mobilePhone] &&
      now - existingCache[mobilePhone] < OTP_COOL_DOWN_INTERVAL
    ) {
      // If user has requested OTP with same number less than 60 sec ago, direct to OTP verification page
      // and continue the timer.
      this.setState({
        otpCountDown:
          OTP_COOL_DOWN_INTERVAL - (now - existingCache[mobilePhone]),
      });
      let otpIntervalId = setInterval(this.executeTimer, 1000);
      this.setState({ page: 3, otpIntervalId, error: '' });
    } else {
      const params = {
        email: this.props.userData.username,
        dialingCode,
        phoneNumber,
        cultureCode: getLanguage(),
      };
      generateOTPService(this.props.clientId, this.props.apiKey, params)
        .then((response) => {
          if (response === null) {
            let cache = {};
            cache[mobilePhone] = now;
            localStorage.setItem(STORAGE_KEY_OTP, JSON.stringify(cache));
            this.setState({ otpCountDown: OTP_COOL_DOWN_INTERVAL });
            let otpIntervalId = setInterval(this.executeTimer, 1000);
            this.setState({ page: 3, otpIntervalId, error: '' });
            // eslint-disable-next-line eqeqeq
          } else if (response.status == 429) {
            if (response.data.secondsLeft > 0) {
              this.setState({
                page: 4,
                error: '',
                otpError: false,
                attemptsLeft: undefined,
                secondsLeft: response.data.secondsLeft,
              });
            } else {
              this.setState({
                error: 'requestOTPRateLimit',
                otpError: true,
                attemptsLeft: undefined,
              });
            }
          } else {
            this.setState({
              error: 'requestOTPFail',
              otpError: true,
              attemptsLeft: undefined,
            });
          }
        })
        .catch(() => {
          this.setState({
            error: 'requestOTPFail',
            otpError: true,
            attemptsLeft: undefined,
          });
        });
    }
  };

  executeTimer = () => {
    const otpCache = this._otpCache();
    if (otpCache) {
      const keys = Object.keys(otpCache);
      if (keys) {
        const number = keys[0];
        if (number === this.state.dialingCode + this.state.phoneNumber) {
          const otpCreatedAt = otpCache[number];
          const now = Math.floor(Date.now() / 1000);
          if (OTP_COOL_DOWN_INTERVAL - (now - otpCreatedAt) > 0) {
            this.setState({
              otpCountDown: OTP_COOL_DOWN_INTERVAL - (now - otpCreatedAt),
            });
            return;
          }
        }
      }
    }
    if (this.state.otpIntervalId) {
      clearInterval(this.state.otpIntervalId);
    }
    this.setState({
      otpCountDown: 0,
      otpIntervalId: null,
      error: '',
      otpError: false,
      attemptsLeft: undefined,
    });
    localStorage.removeItem(STORAGE_KEY_OTP);
  };

  _otpCache() {
    const rawOTPCache = localStorage.getItem(STORAGE_KEY_OTP);
    if (rawOTPCache) {
      return JSON.parse(rawOTPCache);
    }
    return null;
  }

  handleSubmitOTP = (e) => {
    e.preventDefault();

    const { otp } = this.state;
    if (!otp || !validator.isValidOTP(otp)) {
      this.setState({
        error: 'errors.invalidOTP',
        otpError: true,
        attemptsLeft: undefined,
      });
      return;
    }
    if (this.state.otpError && this.state.attemptsLeft === 0) {
      // Skip sending more requests if max attempt already reached.
      this.setState({
        error: 'errors.otpMaxAttemptReached',
        otpError: true,
        attemptsLeft: 0,
      });
      return;
    }
    this.setState({ error: '', otpError: false });

    const userData = this.props.userData;
    if (userData && userData.network && userData.state) {
      // Social sign up / login.
      this.socialWithOTP();
    } else if (this.props.renderGenderAndNameFields) {
      // Email password Sign up
      this.verifyOTP();
    } else {
      // Email password login
      this.signInWithOTP();
    }
  };

  signInWithOTP = () => {
    const { username, password, clientId, gaClientId } = this.props.userData;
    const { dialingCode, phoneNumber, otp } = this.state;
    const promise = loginService(
      username,
      password,
      this.props.dataVisorToken,
      clientId,
      this.props.apiKey,
      gaClientId,
      dialingCode,
      phoneNumber,
      otp
    );
    this._handleOTPLoginSuccess(promise);
  };

  socialWithOTP = () => {
    const {
      gender,
      firstName,
      lastName,
      dialingCode,
      phoneNumber,
      otp,
    } = this.state;
    let data = {
      ...this.props.userData,
      dialingCode,
      phoneNumber,
      otp,
    };
    if (this.props.renderGenderAndNameFields) {
      data = { ...data, gender, firstName, lastName };
    }
    const promise = socialOTPService(
      this.props.clientId,
      this.props.apiKey,
      data
    );
    this._handleOTPLoginSuccess(promise);
  };

  _handleOTPLoginSuccess = (promise) => {
    promise
      .then((response) => {
        const data = response.data;
        const status = response.status;
        if (status >= 400) {
          const otpError = this._showOTPVerificationError(data);
          if (otpError) {
            return;
          }
          this.setState({
            error: 'errors.verifyOTPFail',
            otpError: true,
            attemptsLeft: undefined,
          });
          this.props.onFailureLogin(data.code);
        } else if (data && data.updateForm === true) {
          this.props.onUpdateForm(data);
        } else {
          return authToken
            .set(data)
            .then(() => nativeAppCommunicator.send(data, 'login'))
            .then(() => this.props.onSuccessLogin(data));
        }
      })
      .catch((e) => {
        this.props.onFailureLogin('CONNECTION_ERROR');
        console.error(e);
      });
  };

  verifyOTP = () => {
    const {
      gender,
      firstName,
      lastName,
      dialingCode,
      phoneNumber,
      otp,
    } = this.state;

    const params = {
      email: this.props.userData.username,
      dialingCode,
      phoneNumber,
      otp,
    };
    verifyOTPService(this.props.clientId, this.props.apiKey, params)
      .then((errJson) => {
        if (errJson) {
          const knownError = this._showOTPVerificationError(errJson);
          if (!knownError) {
            this.setState({
              error: 'errors.verifyOTPFail',
              otpError: true,
              attemptsLeft: undefined,
            });
          }
          return;
        }

        this.submitSignupForm(
          gender,
          firstName,
          lastName,
          dialingCode,
          phoneNumber
        );
        if (this.state.otpIntervalId) {
          clearInterval(this.state.otpIntervalId);
        }
        this.setState({
          otpError: false,
          error: '',
          disableSubmitButton: true,
          otpCountDown: 0,
          otpIntervalId: null,
        });
        localStorage.removeItem(STORAGE_KEY_OTP);
      })
      .catch(() => {
        this.setState({
          error: 'errors.verifyOTPFail',
          otpError: true,
          attemptsLeft: undefined,
        });
      });
  };

  _showOTPVerificationError(errJson) {
    if (errJson.code === 'OTP_MAX_ATTEMPT_REACHED') {
      this.setState({
        error: 'errors.otpMaxAttemptReached',
        otpError: true,
        attemptsLeft: 0,
      });
      return true;
    }
    if (errJson.code === 'OTP_VERIFICATION_FAIL' && errJson.attemptsLeft > 0) {
      this.setState({
        error: 'errors.otpTryAgain',
        otpError: true,
        attemptsLeft: errJson.attemptsLeft,
      });
      return true;
    }
    return false;
  }

  _renderPage2(props, state) {
    const button = (
      <Localizer>
        <input
          class="aaw-button"
          type="submit"
          value={<Text id="signupTab.requestOTP">Request OTP</Text>}
          onClick={this.requestOTP}
          role="button"
        />
      </Localizer>
    );
    let buttonContainer;
    if (props.isHorizontal) {
      buttonContainer = (
        <div className={className('aaw-row')}>
          <div className={className('aaw-col aaw-s12 aaw-m6')}>{button}</div>
        </div>
      );
    } else {
      buttonContainer = (
        <div className={className('aaw-bottom-nav--vertical')}>
          <div className={className('aaw-top-padding')}>{button}</div>
        </div>
      );
    }
    return (
      <div>
        <p id="aaw-signup-text-get-otp" tabindex="0">
          <Text
            id="signupTab.getOTP"
            fields={{ number: this._displayNumber(state) }}
          >
            Get a One-Time Password (OTP) sent to your mobile phone{' '}
            {this._displayNumber(state)} for verification.
          </Text>
        </p>
        {buttonContainer}
      </div>
    );
  }

  _renderPage3(props, state) {
    let countdown;
    if (state.otpCountDown >= 1) {
      countdown = (
        <Text
          id={'signupTab.waitXSeconds'}
          plural={state.otpCountDown}
          fields={{ sec: state.otpCountDown }}
        >
          Request a new OTP in {state.otpCountDown} seconds
        </Text>
      );
    } else {
      countdown = (
        <a class="aaw-signup-link" onClick={this.requestOTP} tabindex="0">
          <Text id="signupTab.requestNewOTP">Request a new OTP</Text>
        </a>
      );
    }
    let options;
    if (!state.disableSubmitButton) {
      options = (
        <p tabindex="0">
          <Text id="signupTab.didntGetOTP">Didn’t get your OTP? </Text>
          {countdown}
          <Text id="signupTab.or_start"> or </Text>
          <a class="aaw-signup-link" onClick={this.goBack} tabindex="0">
            <Text id="signupTab.updateYourNumber">
              update your phone number
            </Text>
          </a>
          <Text id="signupTab.or_end" tabindex="-1">
            .
          </Text>
        </p>
      );
    }
    return (
      <div>
        <p id="aaw-signup-text-get-otp" tabindex="0">
          <Text
            id="signupTab.getOTP"
            fields={{ number: this._displayNumber(state) }}
          >
            Get a One-Time Password (OTP) sent to your mobile phone{' '}
            {this._displayNumber(state)} for verification.
          </Text>
        </p>
        <form onSubmit={this.handleSubmitOTP}>
          <div className={className({ 'aaw-row': props.isHorizontal })}>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
              })}
              aria-live="assertive"
            >
              <TextField
                textLabel={<Text id="signupTab.otp">One-Time Password</Text>}
                tabindex="0"
                error={state.otpError}
                tab="additionalSignupFields-otp"
                isOTP={true}
                onValueChange={this.handleChangeOTP}
              />
            </div>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
              })}
            >
              <Localizer>
                <input
                  type="submit"
                  className="aaw-button aaw-signup-button-submit-otp"
                  value={<Text id={'signupTab.submit'} />}
                  disabled={this.state.disableSubmitButton}
                  role="button"
                />
              </Localizer>
            </div>
          </div>
        </form>
        {options}
      </div>
    );
  }

  renderAdditionalFields(props, state) {
    if (state.page === 4) {
      return (
        <div>
          <OTPQuotaReachError secondsLeft={state.secondsLeft} />
        </div>
      );
    }

    let pageContent;
    switch (state.page) {
      case 1:
        pageContent = props.renderGenderAndNameFields
          ? this._renderSignUpPage1(props, state)
          : this._renderSignInPage1(props, state);
        break;
      case 2:
        pageContent = this._renderPage2(props, state);
        break;
      case 3:
        pageContent = this._renderPage3(props, state);
        break;
      default:
        break;
    }
    return (
      <div>
        {this._renderTitle(props, state)}
        {pageContent}
        <div
          id="aaw-signup-back"
          role="button"
          tabindex="0"
          onClick={this.goBack}
        >
          <p>
            <Text id="signupTab.back">Back</Text>
          </p>
        </div>
      </div>
    );
  }

  renderCheckEmail(props) {
    return (
      <div className="aaw-row">
        <div className="aaw-title">
          <Text id="signupTab.activateYourAccount">Activate your account</Text>
        </div>
        <div
          className={className('aaw-vertical-indent', {
            'aaw-row': props.isHorizontal,
          })}
        >
          <div
            className={className('aaw-col aaw-s3', {
              'aaw-m2': props.isHorizontal,
              'aaw-m3': !props.isHorizontal,
            })}
          >
            <div className="aaw-activation-email-icon" />
          </div>
          <div
            className={className('aaw-check-email aaw-col aaw-s9', {
              'aaw-m10': props.isHorizontal,
              'aaw-m9': !props.isHorizontal,
            })}
            role="alert"
            aria-live="assertive"
          >
            <span>
              <Text id="signupTab.emailWithActivationLink">
                An email with an activation link has been sent to:
              </Text>
            </span>
            <div className="aaw-email aaw-valign-wrapper">
              <i className="aaw-done" />
              {this.props.userData.username}
            </div>
            <div
              className={className('aaw-col', {
                'aaw-m5': props.isHorizontal,
                'aaw-m12': !props.isHorizontal,
              })}
            >
              <Localizer>
                <input
                  type="button"
                  className={className('aaw-button', {
                    'aaw--hidden': this.state.hideEmailButton,
                  })}
                  name="go_to_my_email"
                  onClick={this.goToMailPage}
                  value={<Text id="signupTab.goToMail">Go to my email</Text>}
                  role="button"
                />
              </Localizer>
            </div>
          </div>
        </div>
        <div className="aaw-col aaw-s12 aaw-m12 aaw-resend-link__container">
          <Text id="signupTab.noEmail">
            Didn't receive any email? Please check your spam folder or
          </Text>
          &nbsp;
          <Localizer>
            <input
              type="button"
              className="aaw-resend-link-button"
              name="resendLink"
              disabled={this.state.disableResentLink}
              onClick={this.resendActivationLink}
              value={
                <Text id="signupTab.resendLink">resend another email</Text>
              }
              role="button"
            />
          </Localizer>
        </div>
      </div>
    );
  }

  render(props, state) {
    if (state.submitted) {
      return this.renderCheckEmail(props);
    }

    return this.renderAdditionalFields(props, state);
  }
}
