export default {
  getToken() {
    return new Promise((resolve) => {
      const raphael = window.raphael();
      const setKeyFlag = raphael.setAccessKey(process.env.DATAVISOR_KEY);
      const setDomainFlag = raphael.setEnv(process.env.DATAVISOR_DOMAIN);
      if (setKeyFlag === 'success' && setDomainFlag === 'success') {
        raphael.initToken((res) => {
          resolve(res || null);
        });
      } else resolve(null);
    });
  },
};
