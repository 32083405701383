import authWithCookieService from '../services/authWithCookie';
import authToken from './authToken';

export default function (clientId, apiKey) {
  return authWithCookieService(clientId, apiKey)
    .then((response) => {
      if (!response || !response.accessToken) {
        throw new Error('Unable to authenticate using cookie');
      }

      return Promise.all([response, authToken.set(response)]);
    })
    .then(([tokens]) => tokens);
}
