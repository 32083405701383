import { h, Component } from 'preact';
import { Text } from 'preact-i18n';

export default class OTPQuotaReachError extends Component {
  _renderCountdown(secondsLeft) {
    if (secondsLeft >= 86400) {
      const days = Math.ceil(secondsLeft / 86400);
      return (
        <p class="aaw-otp-quota-text">
          <Text
            id="errors.try_again_after_days"
            fields={{ x: days }}
            plural={days}
          />
        </p>
      );
    } else if (secondsLeft >= 3600) {
      const hours = Math.ceil(secondsLeft / 3600);
      return (
        <p class="aaw-otp-quota-text">
          <Text
            id="errors.try_again_after_hours"
            fields={{ x: hours }}
            plural={hours}
          />
        </p>
      );
    } else if (secondsLeft >= 60) {
      const minutes = Math.ceil(secondsLeft / 60);
      return (
        <p class="aaw-otp-quota-text">
          <Text
            id="errors.try_again_after_minutes"
            fields={{ x: minutes }}
            plural={minutes}
          />
        </p>
      );
    }
    return (
      <p class="aaw-otp-quota-text">
        <Text
          id="errors.try_again_after_seconds"
          fields={{ x: secondsLeft }}
          plural={secondsLeft}
        />
      </p>
    );
  }

  render(props) {
    return (
      <div id="aaw-otp-quota-container">
        <div id="aaw-otp-quota-content">
          <img id="aaw-otp-quota-img" />
          <p class="aaw-otp-quota-title">
            <Text id="errors.oops">Oops</Text>
          </p>
          <p class="aaw-otp-quota-text">
            <Text id="errors.otp_quota_reached">
              You’ve reached the maximum number of OTP requests for the day.
            </Text>
          </p>
          {this._renderCountdown(props.secondsLeft)}
        </div>
      </div>
    );
  }
}
