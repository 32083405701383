import className from 'classnames';
import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import { isWechatEnabled } from '../config/feature.flags';
import { getCookieValue, isIEBrowser, isMobileBrowser } from '../helpers';
import authToken from '../helpers/authToken';
import authWithSocial from '../helpers/authWithSocial';
import dataVisor from '../services/dataVisor';
import AdditionalFields from './additionalFields';
import AdditionalWeChatFields from './additionalWechatFields';
import Tab from './common/tab';
import ForgotPassword from './forgotPassword';
import Login from './login';
import Signup from './signup';

export default class App extends Component {
  tabs = ['login', 'signup'];
  state = {
    forgotPasswordTab: false,
    activeTab: 'login',
    loaded: false,
    authenticated: false,
    userId: null,
    defaultEmail: '',
    error: '',
    weChatOpenId: '',
    weChatState: '',
    socialLogin: '',
    additionalFields: false, // User info collection during sign up or 2FA flow during sign in.
    additionalWeChatFieldsTab: false,
    userData: {},
    countries: {},
    responseData: null,
    orientation: '',
    customerSupportRegister: false,
    renderGenderAndNameFields: false, // true for sign up, false for sign in.
    dataVisorToken: null,
  };

  constructor(props) {
    super(props);

    this.state.orientation = props.config.orientation;
    this.clientId = props.config.clientId;
    this.apiKey = props.config.apiKey;
    this.socialButtons = process.env.SOCIAL_BUTTONS
      ? process.env.SOCIAL_BUTTONS.split(',')
      : [];
    this.hideLoginClientIds = process.env.HIDE_LOGIN_CLIENT_IDS
      ? process.env.HIDE_LOGIN_CLIENT_IDS.split(',')
      : [];
    this.hideLoginTab =
      this.props.config.hideLoginTab ||
      this.hideLoginClientIds.indexOf(this.clientId) > -1;
    this.gaClientId = process.env.GA_CLIENT_ID
      ? process.env.GA_CLIENT_ID
      : '_ga';
    this.isMobileBrowser = isMobileBrowser();
    this.wechatEnabled = isWechatEnabled(
      this.isMobileBrowser ? 'mobile' : 'desktop'
    );

    // let hiddenFacebookLoginClientList = "AA011LG,AA004LL,AA009BA,AA010BW,AA011BR";
    // PROD: "AA004LL,AA009BA,AA010BW,AA011BR"
    // STG: AA011LG AA004ABA AB111BBR AA015ABP AA005ABW
    let hiddenFacebookLoginClientList = 'AA004LL,AA009BA,AA004ABA,AB111BBR'; //,AA015ABP,AA005ABW";
    this.hideFBLogin =
      hiddenFacebookLoginClientList.split(',').indexOf(this.clientId) !== -1;

    if (this.isValidToken(props.tokens)) {
      this.setState({
        loaded: true,
        authenticated: true,
        userId: props.tokens.userId,
      });
      this.props.config.successCallback(props.tokens);
    } else {
      this.setLogoutStatus('SESSION_ERROR');
    }
  }

  isValidToken(tokens) {
    return (
      tokens &&
      ['accessToken', 'refreshToken', 'userId'].every((key) => tokens[key])
    );
  }

  isHorizontal = () => {
    return this.props.config.orientation === 'horizontal';
  };

  hasSocialLoginButtons = () => {
    let hasSocialLogin = true;

    if (this.socialButtons[0].length > 8) {
      hasSocialLogin = false;
    }

    if (
      this.socialButtons.indexOf('google') < 0 &&
      this.socialButtons.indexOf('wechat') < 0 &&
      this.hideFBLogin
    ) {
      hasSocialLogin = false;
    }

    if (
      this.socialButtons.indexOf('google') < 0 &&
      this.socialButtons.indexOf('facebook') < 0 &&
      this.wechatEnabled
    ) {
      hasSocialLogin = false;
    }

    return hasSocialLogin;
  };

  componentDidMount() {
    dataVisor
      .getToken()
      .then((token) => {
        this.setState({ dataVisorToken: token });
      })
      .catch((e) => {
        console.log(e);
      });
    this.props.renderCallback(true);
  }

  componentDidUpdate(prevProps, prevState) {
    // Focus widget when we go back from forgot password tab
    if (!this.state.forgotPasswordTab && prevState.forgotPasswordTab) {
      this.widgetComponent.focus();
    }
  }

  handleClick = (tab) => {
    this.setState({ error: '' });

    if (this.state.activeTab === tab) {
      return;
    }

    this.setState((prevState) => {
      const activeTab = prevState.activeTab === 'login' ? 'signup' : 'login';
      return { activeTab };
    });
  };

  handleKeyUp = (e) => {
    // Left or Right arrow
    const charCode = e.which || e.keyCode;
    const tabValue = e.target.id;
    if (charCode === 37 || charCode === 39) {
      this.setState((prevState) => {
        const activeTab = prevState.activeTab === 'login' ? 'signup' : 'login';
        return { activeTab };
      });
    }

    if (charCode === 13) {
      this.setState(() => {
        const activeTab = tabValue === 'aaw-login' ? 'login' : 'signup';
        return { activeTab };
      });
    }
  };

  renderAuthenticatedForm() {
    // This hidden input is needed only for passing automation tests.
    return (
      <input type="hidden" className="aaw-user-id" value={this.state.userId} />
    );
  }

  onLogout = () => {
    authToken.del().then(() => {
      this.setState({ authenticated: false, userId: null });
      const gtmDataLayer = window.gtmDataLayer || [];
      gtmDataLayer.push({
        event: 'login',
        memberId: null,
      });
    });
  };

  onSwitchToLogin = (email) => {
    this.setState({ activeTab: 'login', defaultEmail: email });
  };

  onSwitchToSignup = (email) => {
    this.setState({ activeTab: 'signup', defaultEmail: email });
  };

  renderUnauthneticatedForm(props, state) {
    let widgetFormComponent = null;
    let gaClientId = getCookieValue(this.gaClientId);
    if (state.activeTab === 'login') {
      widgetFormComponent = (
        <Login
          dataVisorToken={this.state.dataVisorToken}
          gaClientId={gaClientId}
          clientId={props.config.clientId}
          apiKey={props.config.apiKey}
          error={state.error}
          email={state.defaultEmail}
          isHorizontal={this.isHorizontal()}
          hasSocialLogin={this.hasSocialLoginButtons()}
          onSwitchToSignup={this.onSwitchToSignup}
          onForgotPassword={this.onForgotPassword}
          onSuccess={this.onSuccessLogin}
          onFailure={this.onFailureLogin}
          onUpdateForm={this.onUpdateForm}
          on2FANeeded={this.renderLogin2FAForm}
        />
      );
    } else {
      widgetFormComponent = (
        <Signup
          clientId={props.config.clientId}
          gaClientId={gaClientId}
          error={state.error}
          customerSupportRegister={state.customerSupportRegister}
          isActivationLinkRequired={state.isActivationLinkRequired}
          email={state.userData.username || state.defaultEmail}
          password={state.userData.password}
          isHorizontal={this.isHorizontal()}
          hasSocialLogin={this.hasSocialLoginButtons()}
          onSubmit={this.onSignupSubmit}
          onSwitchToLogin={this.onSwitchToLogin}
        />
      );
    }

    let showTextOr = false;
    if (!this.hasSocialLoginButtons()) {
      if (
        process.env.LANGUAGE_FILE !== 'zh-CN.json' &&
        !this.socialButtons.length &&
        this.socialButtons.indexOf('${SOCIAL_BUTTONS}') === -1
      ) {
        showTextOr = true;
      } else if (
        process.env.LANGUAGE_FILE === 'zh-CN.json' &&
        this.socialButtons.indexOf('wechat') !== -1
      ) {
        showTextOr = true;
      }
    } else {
      showTextOr = true;
      if (
        this.socialButtons.indexOf('google') === -1 &&
        this.socialButtons.indexOf('wechat') === -1 &&
        this.hideFBLogin
      ) {
        showTextOr = false;
      }

      if (
        process.env.LANGUAGE_FILE === 'zh-CN.json' &&
        this.socialButtons.indexOf('wechat') === -1
      ) {
        showTextOr = false;
      }
    }

    return (
      <Localizer>
        <div
          lang={process.env.LANGUAGE}
          className={className('aaw-container', {
            'aaw-main-container--vertical': !this.isHorizontal(),
            'aaw-main-container--horizontal': this.isHorizontal(),
          })}
          tabIndex="0"
          aria-label={<Text id="widgetTitle">AirAsia Login Signup form</Text>}
          ref={(el) => (this.widgetComponent = el)}
        >
          <div
            className={className({
              'aaw-tabs-container--vertical': !this.isHorizontal(),
              'aaw-tabs-container--vertical-no-tabs':
                (props.config.hideSignupTab || this.hideLoginTab) &&
                !this.isHorizontal(),
              'aaw-tabs-container--horizontal': this.isHorizontal(),
              'aaw-tabs-container--horizontal-no-tabs':
                (props.config.hideSignupTab || this.hideLoginTab) &&
                this.isHorizontal(),
            })}
            role="tablist"
          >
            {this.tabs.map((tab, index) => {
              if (props.config.hideSignupTab || this.hideLoginTab) {
                return null;
              }

              return (
                <Tab
                  key={index}
                  name={tab}
                  onClick={this.handleClick}
                  onKeyUp={this.handleKeyUp}
                  isActive={state.activeTab === tab}
                  orientation={props.config.orientation}
                />
              );
            })}
          </div>
          <div
            className={className({
              'aaw-main-container--new-horizontal': this.isHorizontal(),
              'aaw-main-container--new-vertical': !this.isHorizontal(),
              'aaw-main-container--block': !this.hasSocialLoginButtons(),
            })}
          >
            <div
              className={className(
                { 'aaw-social-container--vertical': true },
                {
                  'aaw--hidden':
                    !this.hasSocialLoginButtons() ||
                    (this.socialButtons.indexOf('google') === -1 &&
                      this.socialButtons.indexOf('wechat') === -1 &&
                      this.hideFBLogin) ||
                    (process.env.LANGUAGE_FILE === 'zh-CN.json' &&
                      this.socialButtons.indexOf('wechat') === -1),
                }
              )}
            >
              {this.renderSocialLink({
                label: 'facebook',
                defaultLoginText: 'Continue with Facebook',
                defaultSignupText: 'Continue with Facebook',
                onClickHandler: this.loginWithFacebook,
                isHidden:
                  process.env.LANGUAGE_FILE === 'zh-CN.json' ||
                  this.socialButtons.indexOf('facebook') === -1 ||
                  this.hideFBLogin,
                loginLinkId: 'facebookLoginButton',
                signupLinkId: 'facebookSignupButton',
              })}
              {this.renderSocialLink({
                label: 'google',
                defaultLoginText: 'Continue with Google',
                defaultSignupText: 'Continue with Google',
                onClickHandler: this.loginWithGoogle,
                isHidden:
                  process.env.LANGUAGE_FILE === 'zh-CN.json' ||
                  this.socialButtons.indexOf('google') === -1,
                loginLinkId: 'googleLoginButton',
                signupLinkId: 'googleSignupButton',
              })}
              {this.renderSocialLink({
                label: 'wechat',
                defaultLoginText: 'Continue with WeChat',
                defaultSignupText: 'Continue with WeChat',
                onClickHandler: this.loginWithWechat,
                isHidden:
                  this.socialButtons.indexOf('wechat') < 0 ||
                  !this.wechatEnabled,
                loginLinkId: 'wechatLoginButton',
                signupLinkId: 'wechatSignupButton',
              })}
            </div>
            <div
              className={className({
                'aaw-text__vertical--center': !this.isHorizontal(),
                'aaw-social-container--column':
                  this.isHorizontal() && showTextOr,
                'aaw--hidden': !showTextOr,
              })}
            >
              {this.isHorizontal()
                ? this.renderTextOr({
                    classes: 'aaw-col aaw-s12 aaw-m1 aaw-text--center',
                    isHidden: !showTextOr,
                  })
                : this.renderTextOr({
                    classes: 'aaw-text__vertical--center',
                    isHidden: !showTextOr,
                  })}
            </div>

            {widgetFormComponent}
          </div>
          <div
            className={className('aaw-new-terms-position', {
              'aaw-col aaw-s12 aaw-m7': this.isHorizontal,
              'aaw-top-padding': !this.isHorizontal,
              'aaw--hidden':
                !this.isHorizontal ||
                state.activeTab === 'login' ||
                !this.hasSocialLoginButtons(),
            })}
          >
            <label className="aaw-agree-terms" htmlFor="agree">
              <Text id="signupTab.iAgree" />
              <Localizer>
                <a
                  href={
                    <Text id="signupTab.termsAndConditionsLink">
                      https://www.airasia.com/my/en/about-us/our-policies.page#BIGLoyalty
                    </Text>
                  }
                  target="_blank"
                  id="termsConditionsLink"
                  aria-label={
                    <Text id="signupTab.termsAndConditions">
                      Terms and conditions, Privacy Policy and Terms of use
                    </Text>
                  }
                >
                  <Text id="signupTab.termsAndConditions">
                    Terms and conditions, Privacy Policy and Terms of use
                  </Text>
                </a>
              </Localizer>
              <Text id="signupTab.termsAndConditionsEnd" />
            </label>
          </div>
        </div>
      </Localizer>
    );
  }

  renderSocialLink({
    label,
    defaultLoginText,
    defaultSignupText,
    onClickHandler,
    isHidden,
    loginLinkId,
    signupLinkId,
  }) {
    return (
      <Localizer>
        <a
          href="javascript:"
          id={this.state.activeTab === 'login' ? loginLinkId : signupLinkId}
          aria-label={
            this.state.activeTab === 'login' ? (
              <Text id={`social.login.${label}`}>{defaultLoginText}</Text>
            ) : (
              <Text id={`social.signup.${label}`}>{defaultSignupText}</Text>
            )
          }
          className={className(`aaw-${label}-button`, {
            'aaw--hidden': isHidden,
          })}
          onClick={onClickHandler}
        >
          <span>
            {this.state.activeTab === 'login' ? (
              <Text id={`social.login.${label}`}>{defaultLoginText}</Text>
            ) : (
              <Text id={`social.signup.${label}`}>{defaultSignupText}</Text>
            )}
          </span>
        </a>
      </Localizer>
    );
  }

  renderTextOr({ classes, isHidden }) {
    return (
      <div className={className(`${classes}`, { 'aaw--hidden': isHidden })}>
        <span className="aaw-or">
          <Text id="or">or</Text>
        </span>
      </div>
    );
  }

  renderForgotPasswordForm(props, state) {
    return (
      <div
        lang={process.env.LANGUAGE}
        className={className('aaw-container', {
          'aaw-forget-password-container--vertical': !this.isHorizontal(),
          'aaw-main-container--horizontal': this.isHorizontal(),
        })}
      >
        <ForgotPassword
          apiKey={this.apiKey}
          clientId={this.clientId}
          onCancel={this.onForgotPasswordCancel}
          isHorizontal={this.isHorizontal()}
          username={state.defaultEmail}
        />
      </div>
    );
  }

  navBackFromAdditionalFields = () => {
    this.setState({
      forgotPasswordTab: false,
      additionalFields: false,
      additionalWeChatFieldsTab: false,
      authenticated: false,
    });
  };

  renderAdditionalFieldsForm() {
    return (
      <div
        lang={process.env.LANGUAGE}
        className={className('aaw-container', {
          'aaw-forget-password-container--vertical': !this.isHorizontal(),
          'aaw-main-container--horizontal': this.isHorizontal(),
        })}
      >
        <AdditionalFields
          dataVisorToken={this.state.dataVisorToken}
          renderGenderAndNameFields={this.state.renderGenderAndNameFields}
          userData={this.state.userData}
          onSuccessLogin={this.onSuccessLogin}
          onFailureLogin={this.onFailureLogin}
          onUpdateForm={this.onUpdateForm}
          onError={this.onSignupError}
          isHorizontal={this.isHorizontal()}
          navBack={this.navBackFromAdditionalFields}
          clientId={this.clientId}
          apiKey={this.apiKey}
        />
      </div>
    );
  }

  renderAdditionalWeChatFieldsForm() {
    return (
      <div
        lang={process.env.LANGUAGE}
        className={className('aaw-container', {
          'aaw-forget-password-container--vertical': !this.isHorizontal(),
          'aaw-main-container--horizontal': this.isHorizontal(),
        })}
      >
        <AdditionalWeChatFields
          weChatOpenId={this.state.weChatOpenId}
          weChatState={this.state.weChatState}
          socialLoginType={this.state.socialLogin}
          onSuccessLogin={this.onSuccessLogin}
          onFailureLogin={this.onFailureLogin}
          isHorizontal={this.isHorizontal()}
          clientId={this.clientId}
          apiKey={this.apiKey}
          countries={this.state.countries}
          response={this.state.responseData}
          isBrowserIE={isIEBrowser()}
        />
      </div>
    );
  }

  onSignupError = (response) => {
    switch (response.code) {
      case 'USER_ALREADY_EXISTS':
        this.setState({
          additionalFields: false,
          activeTab: 'signup',
          error: 'errors.emailExists',
          defaultEmail: this.state.userData.username,
          isActivationLinkRequired: false,
        });
        break;
      case 'USER_INACTIVE':
        this.setState({
          additionalFields: false,
          activeTab: 'signup',
          error: 'errors.userInactiveRegister',
          customerSupportRegister: true,
          defaultEmail: this.state.userData.username,
        });
        break;
      case 'USER_TERMINATED':
        this.setState({
          additionalFields: false,
          activeTab: 'signup',
          error: 'errors.userForgotten',
          isActivationLinkRequired: true,
          defaultEmail: this.state.userData.username,
        });
        break;
      default:
        this.setState({
          additionalFields: false,
          activeTab: 'signup',
          error: 'errors.serverError',
        });
    }
  };

  onSuccessLogin = (data) => {
    this.setState({
      authenticated: true,
      additionalFields: false,
      additionalWeChatFieldsTab: false,
      userId: data.userId,
    });
    this.props.config.successCallback(data);
    if (data.network && data.network == 'wechat') {
      const gtmDataLayer = window.gtmDataLayer || [];
      gtmDataLayer.push({
        event: 'signupSuccess',
        signupMethod: 'wechat',
        email: data.email,
      });
    } else {
      const gtmDataLayer = window.gtmDataLayer || [];
      gtmDataLayer.push({
        event: 'login',
        loginMethod: 'email',
        loyaltyId: data.loyaltyId,
      });
    }
  };

  setLogoutStatus = (err) => {
    this.setState({
      loaded: true,
      authenticated: false,
      activeTab: this.hideLoginTab ? 'signup' : 'login',
    });
    this.onFailureLogin(err);
  };

  onFailureLogin = (error) => {
    if (this.props.config.failureCallback) {
      this.props.config.failureCallback(error);
    }
  };

  onUpdateForm = (response) => {
    this.setState({
      additionalWeChatFieldsTab: response.updateForm,
      responseData: response,
    });
  };

  renderLogin2FAForm = (userData) => {
    this.setState({
      additionalFields: true,
      userData,
      renderGenderAndNameFields: false,
    });
  };

  renderSocial2FAForm = (userData) => {
    // If personal info is complete, only render mobile phone fields for 2FA. (Sign in)
    // Otherwise, render input fields for user to fill in. (Sign up)
    const { firstName, lastName, gender, ...others } = userData;
    const renderGenderAndNameFields = !firstName || !lastName || !gender;
    this.setState({
      additionalFields: true,
      userData: renderGenderAndNameFields ? userData : others,
      renderGenderAndNameFields,
    });
  };

  onForgotPassword = (data) => {
    this.setState({ defaultEmail: data.username });
    this.setState({ forgotPasswordTab: true });
  };

  onForgotPasswordCancel = (e) => {
    e.preventDefault();
    this.setState({ forgotPasswordTab: false });
  };

  onSignupSubmit = (data) => {
    this.setState({
      additionalFields: true,
      userData: data,
      renderGenderAndNameFields: true,
    });
  };

  /**
   * Checks if the txt is url.
   * http://hello.com -> true
   * https://h.org -> true
   * www.angry.com -> true
   * http://localhost:8080 -> false
   * http://local.aa.com:8090 -> true
   * https://local.aa.eu -> true
   * @param {*} txt
   */
  isUrl(txt) {
    return /((https|http):\/\/)?(www)?.+(\.[a-z]+)(:[0-9]+)?/.test(txt);
  }

  loginWith(network, additionalData = {}) {
    authWithSocial(network, {
      authType: this.state.activeTab,
      clientId: this.clientId,
      gaClientId: this.gaClientId,
      ...additionalData,
    })
      .then((response) => {
        if (response.otpForm) {
          this.renderSocial2FAForm(response);
          return;
        }

        if (response.accessToken) {
          const tokens = {
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            userId: response.userId,
            loyaltyId: response.loyaltyId,
          };

          if (response.updateForm === 'false') {
            this.setState({ authenticated: true });
            this.props.config.successCallback(tokens);
          } else {
            this.setState({ additionalFields: true, responseData: tokens });
          }

          return;
        }

        if (response.network === 'wechat') {
          this.setState({
            additionalWeChatFieldsTab: true,
            socialLogin: 'wechat',
            weChatOpenId: response.openId,
            weChatState: response.state,
          });
        }
      })
      .catch((error) => {
        if (error) {
          switch (error) {
            case 'USER_NOT_ACTIVATED':
              this.setState({ error: 'errors.userNotActivated' });
              break;
            case 'USER_INACTIVE':
              this.setState({ error: 'errors.userInactive' });
              break;
            case 'USER_LOCKED':
              this.setState({ error: 'errors.notAllowedToLogin' });
              break;
            case 'USER_TERMINATED':
              this.setState({ error: 'errors.notAllowedToLogin' });
              break;
            case 'ACCESS_DENIED':
            case 'INSUFFICIENT_PERMISSIONS':
              break;
            default:
          }
          this.onFailureLogin(error);
        }
      });
  }

  getPopupOptions() {
    const windowOptions =
        'scrollbars=1,resizable=1,toolbar=0,location=0,dialog=1',
      width = 750,
      height = 520,
      winHeight = screen.height,
      winWidth = screen.width;
    let left,
      top = 0;

    left = Math.round(winWidth / 2 - width / 2);
    if (winHeight > height) {
      top = Math.round(winHeight / 2 - height / 2);
    }

    return `${windowOptions},width=${width},height=${height},left=${left},top=${top}`;
  }

  loginWithFacebook = () => {
    this.loginWith('facebook');
  };

  loginWithGoogle = () => {
    this.loginWith('google');
  };

  loginWithLine = () => {
    this.loginWith('line');
  };

  loginWithWeibo = () => {
    this.loginWith('weibo');
  };

  loginWithQq = () => {
    this.loginWith('qq');
  };

  loginWithWechat = () => {
    this.loginWith('wechat');
  };

  render(props, state) {
    if (state.loaded) {
      let content;

      if (state.forgotPasswordTab) {
        content = this.renderForgotPasswordForm(props, state);
      } else if (state.additionalFields) {
        content = this.renderAdditionalFieldsForm();
      } else if (state.additionalWeChatFieldsTab) {
        content = this.renderAdditionalWeChatFieldsForm();
      } else if (state.authenticated) {
        content = this.renderAuthenticatedForm(props, state);
      } else {
        content = this.renderUnauthneticatedForm(props, state);
      }
      return content;
    }
    return null;
  }
}
