import fetch from 'unfetch';

export default (accessToken, clientId, apiKey) =>
  fetch(`${process.env.API_GW_URL}/sso/v2/short-session`, {
    method: 'head',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: accessToken,
      'x-api-key': apiKey,
      'x-aa-client-id': clientId,
    },
  }).then((response) => {
    return !!response.ok;
  });
