/* eslint react/jsx-no-bind: off */
import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';

export default class Errors extends Component {
  state = {
    disableResentLink: false,
  };

  componentDidUpdate() {
    if (this.props.isFocused) {
      this.errorMessage.focus();
    }
  }

  render(props) {
    if (props.error) {
      return (
        <div
          className="aaw-error-message"
          onBlur={props.onBlur}
          ref={(el) => (this.errorMessage = el)}
          tabIndex="-1"
          id="aaw-error"
          role="alert"
          aria-live="assertive"
        >
          <div className="aaw-right-padding">
            <span className="aaw-red-round">!</span>
          </div>
          <div>
            <Text id={props.error} fields={{ email: props.email }} />
            &nbsp;
            {props.resetLink && (
              <Localizer>
                <a
                  className="aaw-error-link"
                  href="javascript:"
                  onClick={() => {
                    const params = { username: props.email };
                    props.linkAction(Object.assign({}, params));
                  }}
                  aria-label={
                    <Text id="errors.resetPassword">reset your password</Text>
                  }
                >
                  <Text id="errors.resetPassword">reset your password</Text>
                </a>
              </Localizer>
            )}
            {props.isTerminated && (
              <Localizer>
                <a
                  className="aaw-error-link"
                  href="javascript:"
                  onClick={() => {
                    const params = { username: props.email };
                    props.linkAction(Object.assign({}, params));
                  }}
                  aria-label={
                    <Text id="errors.userResetPassword">
                      reset your password
                    </Text>
                  }
                >
                  <Text id="errors.userResetPassword">reset your password</Text>
                </a>
              </Localizer>
            )}
            {props.isTerminated && (
              <Localizer>
                <Text id="errors.userResetPasswordAdditional" />
              </Localizer>
            )}
            {props.isTerminatedNotify && (
              <Localizer>
                <Text
                  id="errors.userTerminatedNotify"
                  fields={{ email: props.email }}
                >
                  An email to reset your password has been sent to
                </Text>
              </Localizer>
            )}
            {props.activationLink && (
              <Localizer>
                <a
                  className={
                    this.state.disableResentLink
                      ? 'aaw-error-link-disabled'
                      : 'aaw-error-link'
                  }
                  href="javascript:"
                  onClick={(e) => {
                    props.linkFunction(e);
                    this.setState({ disableResentLink: true });
                  }}
                  aria-label={
                    <Text id="errors.userNotActivatedLink">
                      Request an activation link.
                    </Text>
                  }
                >
                  <Text id="errors.userNotActivatedLink">
                    Request an activation link.
                  </Text>
                </a>
              </Localizer>
            )}
            {props.activationLink && (
              <Localizer>
                <Text id="errors.userNotActivatedEnd"> to log in.</Text>
              </Localizer>
            )}
            {props.signupLink && (
              <Localizer>
                <a
                  className="aaw-error-link"
                  href="javascript:"
                  onClick={() => {
                    props.linkAction(props.email);
                  }}
                  aria-label={
                    <Text
                      id="errors.signupWithEmail"
                      fields={{ email: props.email }}
                    >
                      signup with {props.email}?
                    </Text>
                  }
                >
                  <Text
                    id="errors.signupWithEmail"
                    fields={{ email: props.email }}
                  >
                    signup with {props.email}?
                  </Text>
                </a>
              </Localizer>
            )}
            {props.loginLink && (
              <Localizer>
                <a
                  className="aaw-error-link"
                  href="javascript:"
                  onClick={() => {
                    props.linkAction(props.email);
                  }}
                  aria-label={
                    <Text
                      id="errors.loginWithEmail"
                      fields={{ email: props.email }}
                    >
                      signup with {props.email}?
                    </Text>
                  }
                >
                  <Text
                    id="errors.loginWithEmail"
                    fields={{ email: props.email }}
                  >
                    signup with {props.email}?
                  </Text>
                </a>
              </Localizer>
            )}
            {props.customerSupportLogin && (
              <Localizer>
                <a
                  href={
                    <Text id="errors.customerSupportLinkUrl">
                      https://support.airasia.com/s/?language=en_GB
                    </Text>
                  }
                  className={
                    this.state.disableResentLink
                      ? 'aaw-error-link-disabled'
                      : 'aaw-error-link'
                  }
                  aria-label={
                    <Text id="errors.customerSupportLink">
                      customer support link
                    </Text>
                  }
                >
                  <Text id="errors.customerSupportLink">
                    customer support link
                  </Text>
                </a>
              </Localizer>
            )}
            {props.customerSupportLogin && (
              <Localizer>
                <Text id="errors.customerSupportLinkEndLogin">
                  if you wish to log in using this email
                </Text>
              </Localizer>
            )}
            {props.customerSupportLocked && (
              <Localizer>
                <a
                  href={
                    <Text id="errors.customerSupportLinkUrl">
                      https://support.airasia.com/s/?language=en_GB
                    </Text>
                  }
                  className={
                    this.state.disableResentLink
                      ? 'aaw-error-link-disabled'
                      : 'aaw-error-link'
                  }
                  aria-label={
                    <Text id="errors.customerSupportLink">
                      customer support link
                    </Text>
                  }
                >
                  <Text id="errors.customerSupportLink">
                    customer support link
                  </Text>
                </a>
              </Localizer>
            )}
            {props.customerSupportLocked && (
              <Localizer>
                <Text id="errors.customerSupportLinkEndLocked">
                  to unlock your account
                </Text>
              </Localizer>
            )}
            {props.customerSupportRegister && (
              <Localizer>
                <a
                  href={
                    <Text id="errors.customerSupportLinkUrl">
                      https://support.airasia.com/s/?language=en_GB
                    </Text>
                  }
                  className={
                    this.state.disableResentLink
                      ? 'aaw-error-link-disabled'
                      : 'aaw-error-link'
                  }
                  aria-label={
                    <Text id="errors.customerSupportLink">
                      customer support link
                    </Text>
                  }
                >
                  <Text id="errors.customerSupportLink">
                    customer support link
                  </Text>
                </a>
              </Localizer>
            )}
            {props.customerSupportRegister && (
              <Localizer>
                <Text id="errors.customerSupportLinkEndRegister">
                  if you wish to register using this email
                </Text>
              </Localizer>
            )}
            {props.attemptsLeft > 0 && (
              <Localizer>
                <Text
                  id="errors.otpXAttemptsLeft"
                  fields={{ x: props.attemptsLeft }}
                  plural={props.attemptsLeft}
                >
                  You have limited attempts left.
                </Text>
              </Localizer>
            )}
          </div>
        </div>
      );
    }
    return null;
  }
}
