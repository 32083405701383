import { h, Component } from 'preact';
import { Text } from 'preact-i18n';
import TextField from '../common/textField';
import className from 'classnames';

export default class MobileNumberField extends Component {
  state = {
    dialingCode: this.props.dialingCode || '60',
    mobileNumber: this.props.mobileNumber || '',
  };

  onChange = (e) => {
    const select = e.target;
    const prevDailingCode = this.state.dialingCode;
    const dialingCode = select.options[select.selectedIndex].value;
    this.setState({ dialingCode });
    if (prevDailingCode !== dialingCode) {
      this.props.onValueChange(dialingCode, this.state.mobileNumber);
    }
  };

  handleChangeMobileNumber = (mobileNumber) => {
    const prevMobileNumber = this.state.mobileNumber;
    this.setState({ mobileNumber });
    if (prevMobileNumber !== mobileNumber) {
      this.props.onValueChange(this.state.dialingCode, mobileNumber);
    }
  };

  buildOptions(options) {
    let items = [];
    if (options) {
      for (let i = 0; i < options.length; i++) {
        let item = options[i];
        items.push(
          <option
            value={item.dialingCode}
            selected={item.dialingCode === this.state.dialingCode}
          >
            {item.name + ' (+' + item.dialingCode + ')'}
          </option>
        );
      }
    }
    return items;
  }

  render(props) {
    return (
      <div>
        <div
          className={`aaw-mobile-number-container ${
            props.error ? 'aaw-input--error' : ''
          }`}
        >
          <div
            id="aaw-mobile-number-dropdown"
            class={className({ disabled: props.disabled }, 'dropdown-display')}
          >
            <label
              className={className({
                'aaw-active': true,
                disabled: props.disabled,
              })}
              htmlFor={`aaw-mobile-number-dropdown`}
              role="tooltip"
            >
              <Text id="signupTab.dialingCode">Dialing code</Text>
            </label>
            <p>{'+' + this.state.dialingCode}</p>
            <select onchange={this.onChange}>
              {this.buildOptions(props.listItems.countries)}
            </select>
          </div>
          <div className={'aaw-mobile-number-input'}>
            <TextField
              isMobile={true}
              error={props.error}
              textLabel={<Text id="signupTab.mobilePhone">Mobile phone</Text>}
              tab="additionalSignupFields-mobileNumber"
              onValueChange={this.handleChangeMobileNumber}
              defaultValue={this.state.mobileNumber}
              tooltipText={
                <Text id="tooltipTextMobileNumber">
                  Please enter a valid phone number to receive one-time password
                  for account validation.
                </Text>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
