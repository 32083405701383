import { h, Component } from 'preact';
import className from 'classnames';

export default class TextField extends Component {
  state = {
    active: false,
    value: this.props.defaultValue || '',
  };

  onChange = (e) => {
    let value = e.target.value;
    if (this.props.isMobile || this.props.isOTP) {
      value = value.replace(/[^0-9]/g, '');
      if (this.props.isMobile) {
        value = value.substring(0, 16);
      }
    }
    this.setState((prevState, props) => {
      props.onValueChange(value);
      return { value };
    });
  };

  onInput = (e) => {
    let value = e.target.value;
    if (this.props.isMobile || this.props.isOTP) {
      value = value.replace(/[^0-9]/g, '');
      if (this.props.isMobile) {
        value = value.substring(0, 16);
      }
    }
    this.setState((prevState, props) => {
      props.onValueChange(value);
      return { value };
    });
  };

  onFocus = () => {
    this.setState({ active: true });
  };

  onBlur = (e) => {
    const value = e.target.value;
    this.setState((prevState, props) => {
      props.onValueChange(value);
      return { value };
    });
    if (!this.state.value) {
      this.setState({ active: false });
    }
  };

  onAnimationStart = ({ animationName }) => {
    if (animationName === 'onAutoFillStart') {
      this.onFocus();
    }
  };

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.loginInput.focus();
      this.setState({ active: true });
    }
    this.loginInput.addEventListener(
      'animationstart',
      this.onAnimationStart,
      false
    );
  }

  componentWillUnmount() {
    if (this.loginInput) {
      this.loginInput.removeEventListener(
        'animationstart',
        this.onAnimationStart,
        false
      );
    }
  }

  render(props) {
    return (
      <div
        className={className(
          'aaw-input-field aaw-form__input',
          { 'aaw-mobile-number-input--border': props.isMobile },
          { 'aaw-input--error': props.error }
        )}
      >
        <input
          id={`text-input--${props.tab}`}
          value={this.state.value}
          type="text"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onInput={this.onInput}
          aria-describedby={props.error && 'aaw-error'}
          ref={(el) => (this.loginInput = el)}
          aria-invalid={props.error}
          disabled={props.disabled}
          maxLength={this.props.maxLength}
        />
        <label
          className={className({
            'aaw-active': this.state.active || this.state.value,
          })}
          htmlFor={`text-input--${props.tab}`}
          aria-describedby={`text-input--${props.tab}`}
          role="tooltip"
        >
          {this.props.textLabel}
        </label>
        <span className="aaw-tooltiptext">{this.props.tooltipText}</span>
      </div>
    );
  }
}
