import fetch from 'unfetch';

// Deprecated: switch this back to /auth to stop supporting cookie.
export default (clientId, apiKey, accessToken, gaClientId, withCookie = true) =>
  fetch(
    `${process.env.API_GW_URL}/${
      withCookie ? 'sso' : 'auth'
    }/v2/logout?clientId=${clientId}&gaClientId=${gaClientId}`,
    {
      method: 'post',
      credentials: withCookie ? 'include' : 'omit',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: accessToken,
        'x-api-key': apiKey,
        'x-aa-client-id': clientId,
      },
      body: JSON.stringify({}),
    }
  )
    .then((response) => {
      if (response.ok) {
        return {
          ok: response.ok,
        };
      }

      return response.json();
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
