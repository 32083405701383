import fetch from 'unfetch';

export default (clientId, apiKey) =>
  fetch(`${process.env.API_GW_URL}/sso/v2/authorization?clientId=${clientId}`, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
  }).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      error.status = response.status;
      throw error;
    }

    return response.json();
  });
