import { CrossStorageClient } from 'cross-storage';

class LocalStorageClient extends CrossStorageClient {
  /**
   * Flag to fallback to use native localStorage.
   * @type {boolean}
   */
  fallback = false;

  del(...keys) {
    keys.map((key) => localStorage.removeItem(key));

    if (this.fallback) {
      console.log('SSO: cross-storage.del using fallback mode');
      return Promise.resolve();
    }

    return super.del(...keys).catch(() => {
      console.log('SSO: cross-storage.del falling back to localstorage');
      this.fallback = true;
    });
  }

  set(key, value, ttl) {
    localStorage.setItem(key, value);

    if (this.fallback) {
      console.log('SSO: cross-storage.set using fallback mode');
      return Promise.resolve();
    }

    return super.set(key, value, ttl).catch(() => {
      console.log('SSO: cross-storage.set falling back to localstorage');
      this.fallback = true;
    });
  }

  get(...keys) {
    if (this.fallback) {
      console.log('SSO: cross-storage.get using fallback mode');

      if (keys.length === 1) {
        return Promise.resolve(localStorage.getItem(keys[0]));
      }

      return Promise.resolve(keys.map((key) => localStorage.getItem(key)));
    }

    return super.get(...keys).catch(() => {
      console.log('SSO: cross-storage.get falling back to localstorage');
      this.fallback = true;
      return this.get(...keys);
    });
  }

  setJSON(key, value, ttl) {
    return this.set(key, JSON.stringify(value), ttl);
  }

  getJSON(...keys) {
    return this.get(...keys).then((values) => {
      if (Array.isArray(values)) {
        return values.map((value) => JSON.parse(value));
      }

      return JSON.parse(values);
    });
  }
}

export default LocalStorageClient;
