export default {
  isEmail(value) {
    return /^[_A-Za-z0-9-]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,10})$/i.test(
      value
    );
  },
  isPassword(value) {
    value = String(value);
    return /^(?=.*\d)(?=.*?[A-Z])(?=.*?[a-z])(?=.*[a-zA-Z.@#$%^&+=!])(?!.*[<>.,~]).{8,16}$/.test(
      value
    );
  },
  isName(value) {
    return /^[a-zA-Z ]+$/.test(value);
  },
  isNotNull(value) {
    value = String(value).trim();
    return value.length !== 0;
  },
  isValidDialingCode(value) {
    value = String(value).trim();
    return /^[0-9]+$/.test(value);
  },
  isValidPhoneNumber(value) {
    value = String(value).trim();
    if (value.length < 5) {
      return false;
    }
    return /^[0-9]+$/.test(value);
  },
  isValidOTP(value) {
    value = String(value).trim();
    return /^[0-9]{6}$/.test(value);
  },
};
