import fetch from 'unfetch';

// Deprecated: switch this back to /auth to stop supporting cookie.
export default (clientId, apiKey, data) =>
  fetch(
    `${process.env.API_GW_URL}/sso/v2/authorization/social/${data.network}/otp?clientId=${clientId}`,
    {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify(data),
    }
  ).then((response) => {
    return response.json().then((data) => {
      return {
        status: response.status,
        data,
      };
    });
  });
