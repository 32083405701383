import fetch from 'unfetch';

export default (email, emailLanguageCode, clientId, apiKey) =>
  fetch(`${process.env.API_GW_URL}/um/v2/password-recovery-keys`, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      'x-aa-client-id': clientId,
    },
    body: JSON.stringify({
      email,
      emailLanguageCode,
    }),
  }).then((response) => {
    if (response.ok) return;
    return response.text().then((body) => {
      const error = JSON.parse(body).code;
      throw error;
    });
  });
