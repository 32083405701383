import fetch from 'unfetch';

export default (culture) => {
  let path = culture.toLowerCase();
  if (path === 'en-tt') {
    path = 'th-th'; // sch bucket doesn't have en-tt json.
  }
  return fetch(
    `${process.env.BUCKET_URL}/datasource/country/${path}/file.json`,
    {
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    }
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
};
