import fetch from 'unfetch';

export default (clientId, apiKey, refreshToken) =>
  fetch(
    `${process.env.API_GW_URL}/sso/v2/authorization/by-refresh-token?clientId=${clientId}`,
    {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({
        refreshToken,
      }),
    }
  ).then((response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      error.status = response.status;
      throw error;
    }

    return response.json();
  });
