import fetch from 'unfetch';

export default (params, userId, accessToken, clientId, apiKey) =>
  fetch(`${process.env.API_GW_URL}/um/v2/users/${userId}`, {
    method: 'PATCH',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: accessToken,
      'x-api-key': apiKey,
      'x-aa-client-id': clientId,
    },
    body: JSON.stringify(params),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
