import fetch from 'unfetch';

export default () => {
  let url = `${process.env.API_GW_URL}/config/v2/clients/by-origin`;
  const path = window.location.href
    .replace(window.location.origin, '')
    .split('/')
    .filter((e) => e)[0];

  if (path) {
    url += `?path=${path}`;
  }

  return fetch(url, {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        data.redirectUrl = response.headers.get('x-aa-redirecturl');
        return data;
      });
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
};
