import fetch from 'unfetch';

export default (email, emailLanguageCode, clientId, apiKey) =>
  fetch(`${process.env.API_GW_URL}/um/v2/user-activation-keys`, {
    method: 'post',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      'x-aa-client-id': clientId,
    },
    body: JSON.stringify({
      email,
      emailLanguageCode,
    }),
  }).then((response) => {
    if (response.ok) {
      return response.text();
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
