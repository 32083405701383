import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import className from 'classnames';
import { getLanguage } from '../../helpers';
import validator from '../../helpers/validators';
import authToken from '../../helpers/authToken';
import TextField from '../common/textField';
import weChatService from '../../services/wechat';
import weChatServiceSSO from '../../services/wechatSSO';
import countryService from '../../services/getCountries';
import userUpdateService from '../../services/updateUser';
import Errors from '../errors';
import nativeAppCommunicator from '../../helpers/nativeAppCommunicator';

export default class AdditionalWeChatFields extends Component {
  state = {
    gender: 'male',
    firstName: '',
    lastName: '',
    emailAddress: '',
    error: '',
    firstNameError: false,
    lastNameError: false,
    emailAddressError: false,
    countries: {},
    isProcessing: false,
  };

  constructor(props) {
    super(props);

    countryService(getLanguage())
      .then((response) => {
        this.setState({ countries: response });
      })
      .catch(() => {});
  }

  handleChangeGender = (e) => {
    this.setState({ gender: e.currentTarget.value });
  };

  handleChangeFirstName = (firstName) => {
    this.setState({ firstName });
  };

  handleChangeLastName = (lastName) => {
    this.setState({ lastName });
  };

  handleChangeEmailAddress = (emailAddress) => {
    this.setState({ emailAddress });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const socialLoginType = e.target.id;

    let { gender, firstName, lastName, emailAddress } = this.state;

    if (socialLoginType != 'wechat') {
      emailAddress = 'email@domain.com';
    }

    if (
      (!validator.isNotNull(firstName) &&
        !validator.isNotNull(lastName) &&
        !validator.isNotNull(emailAddress)) ||
      !validator.isNotNull(firstName)
    ) {
      this.setState({
        error: 'errors.emptyGivenName',
        firstNameError: !validator.isNotNull(firstName),
        lastNameError: !validator.isNotNull(lastName),
        emailAddressError: !validator.isNotNull(emailAddress),
      });
      return;
    }

    if (
      (validator.isNotNull(firstName) &&
        !validator.isNotNull(lastName) &&
        !validator.isNotNull(emailAddress)) ||
      !validator.isNotNull(lastName)
    ) {
      this.setState({
        error: 'errors.emptyLastName',
        firstNameError: !validator.isNotNull(firstName),
        lastNameError: !validator.isNotNull(lastName),
        emailAddressError: !validator.isNotNull(emailAddress),
      });
      return;
    }

    if (
      (validator.isNotNull(firstName) &&
        validator.isNotNull(lastName) &&
        !validator.isNotNull(emailAddress)) ||
      !validator.isNotNull(emailAddress)
    ) {
      this.setState({
        error: 'errors.emptyEmailAddress',
        firstNameError: !validator.isNotNull(firstName),
        lastNameError: !validator.isNotNull(lastName),
        emailAddressError: !validator.isNotNull(emailAddress),
      });
      return;
    }

    if (validator.isNotNull(firstName) && !validator.isName(firstName)) {
      this.setState({
        error: 'errors.romanAlphabetsOnly',
        firstNameError: true,
        lastNameError: false,
        emailAddressError: false,
      });
      return;
    }

    if (validator.isNotNull(lastName) && !validator.isName(lastName)) {
      this.setState({
        error: 'errors.romanAlphabetsOnly',
        firstNameError: false,
        lastNameError: true,
        emailAddressError: false,
      });
      return;
    }

    if (validator.isNotNull(emailAddress) && !validator.isEmail(emailAddress)) {
      this.setState({
        error: 'errors.invalidEmail',
        firstNameError: false,
        lastNameError: false,
        emailAddressError: true,
      });
      return;
    }
    this.setState({
      error: '',
      firstNameError: false,
      lastNameError: false,
      emailAddressError: false,
      usernameError: false,
    });

    let params = {
      gender,
      firstName,
      lastName,
      emailAddress,
      openid: this.props.weChatOpenId,
      state: this.props.weChatState,
      clientId: this.props.clientId,
      origin: this.props.origin,
      socialLoginType,
      authorization: this.props.weChatState,
    };

    for (let key in params) {
      if (params[key] === '') {
        delete params[key];
      }
    }

    if (!this.state.isProcessing) {
      this.setState({ isProcessing: true });
      if (socialLoginType !== 'wechat') {
        delete params.emailAddress;
        userUpdateService(
          params,
          this.props.response.userId,
          this.props.response.accessToken,
          this.props.clientId,
          this.props.apiKey
        )
          .then(() => authToken.set(this.props.response))
          .then(() => nativeAppCommunicator.send(this.props.response, 'login'))
          .then(() => this.props.onSuccessLogin(this.props.response))
          .catch((e) => {
            this.setState({ isProcessing: false });
            console.error(e);
          });

        return;
      }

      this.updateWechatUserDetails(params);
    }
  };

  updateWechatUserDetails(params) {
    weChatService(params, this.props.apiKey, this.props.clientId)
      .then((response) => {
        if (response.errormsg) {
          this.props.onFailureLogin(response);
          this.setState({
            error: 'errors.emailExists',
            usernameError: true,
            email: this.state.emailAddress,
            isProcessing: false,
          });
          return;
        }

        const tokens = { ...response };
        delete tokens.email;
        delete tokens.loyaltyId;

        return (
          authToken
            .set(tokens)
            .then(() => nativeAppCommunicator.send(tokens, 'login'))
            // Deprecated: remove this line and its import above to stop supporting cookie.
            .then(() => weChatServiceSSO(response, this.props.apiKey))
            .then(() => {
              tokens.email = params.emailAddress;
              tokens.network = 'wechat';
              this.props.onSuccessLogin(tokens);
            })
        );
      })
      .catch((e) => {
        this.setState({ isProcessing: false });
        console.error(e);
      });
  }

  renderAdditionalWeChatFields(props, state) {
    let emailAddress, aawLoader;

    if (props.socialLoginType === 'wechat') {
      emailAddress = (
        <div
          className={className({
            'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
          })}
        >
          <TextField
            textLabel={<Text id="signupTab.emailAddress">Email Address</Text>}
            error={state.emailAddressError}
            tab="additionalFormFields-email"
            onValueChange={this.handleChangeEmailAddress}
            disabled={this.state.isProcessing}
          />
        </div>
      );
    }

    if (!props.isBrowserIE) {
      aawLoader = (
        <div
          className={className('aaw-button aaw-loader', {
            'aaw--hidden': !this.state.isProcessing,
          })}
        >
          <svg className="circular" viewBox="25 25 50 50">
            <circle
              className="path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke-width="2"
              stroke-miterlimit="10"
            />
          </svg>
        </div>
      );
    } else {
      aawLoader = (
        <div
          className={className('aaw-button aaw-loader-wrapper', {
            'aaw--hidden': !this.state.isProcessing,
          })}
        >
          <div class="aaw-custom-loader">
            <div />
          </div>
        </div>
      );
    }
    return (
      <form onSubmit={this.handleSubmit} id={props.socialLoginType}>
        <Errors error={state.error} email={state.email} />
        <div className="aaw-title aaw-vertical-indent">
          <Text id="signupTab.weChatHeader1">
            We'd like to know your name and email
          </Text>
        </div>
        <span role="alert" aria-live="assertive">
          <Text id="signupTab.weChatHeader2">
            ...so we can share any good deals with you
          </Text>
        </span>
        <div
          className={className('aaw-vertical-indent', {
            'aaw-row': props.isHorizontal,
          })}
        >
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6 aaw-el--top-padding': props.isHorizontal,
              'aaw-top-padding': !props.isHorizontal,
            })}
          >
            <input
              type="radio"
              name="gender"
              id="male"
              value="male"
              className="aaw-filled-in"
              onChange={this.handleChangeGender}
              checked={this.state.gender === 'male'}
              disabled={this.state.isProcessing}
            />
            <label htmlFor="male">
              <Text id="signupTab.male">Male</Text>
            </label>
            <input
              type="radio"
              name="gender"
              id="female"
              value="female"
              className="aaw-filled-in"
              onChange={this.handleChangeGender}
              checked={this.state.gender === 'female'}
              disabled={this.state.isProcessing}
            />
            <label htmlFor="female">
              <Text id="signupTab.female">Female</Text>
            </label>
          </div>
        </div>
        <div className={className({ 'aaw-row': props.isHorizontal })}>
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
            })}
          >
            <TextField
              textLabel={<Text id="signupTab.givenName">Given name</Text>}
              error={state.firstNameError}
              tab="additionalFormFields-firstName"
              onValueChange={this.handleChangeFirstName}
              tooltipText={
                <Text id="tooltipText">
                  Name must match government-issued photo ID in Roman alphabet (
                  A-Z)
                </Text>
              }
              disabled={this.state.isProcessing}
            />
          </div>
          <div
            className={className({
              'aaw-col aaw-s12 aaw-m6': props.isHorizontal,
            })}
          >
            <TextField
              textLabel={
                <Text id="signupTab.familyName">Family name/Surname</Text>
              }
              error={state.lastNameError}
              tab="additionalFormFields-lastName"
              onValueChange={this.handleChangeLastName}
              tooltipText={
                <Text id="tooltipText">
                  Name must match government-issued photo ID in Roman alphabet (
                  A-Z)
                </Text>
              }
              disabled={this.state.isProcessing}
            />
          </div>
          {emailAddress}
        </div>
        <div
          className={className({
            'aaw-row aaw-align--vertical': props.isHorizontal,
            'aaw-bottom-nav--vertical': !props.isHorizontal,
          })}
        >
          <div
            className={className({
              'aaw-col aaw-s6 aaw-m4 aaw-el--top-padding': props.isHorizontal,
              'aaw-top-padding': !props.isHorizontal,
            })}
          >
            <Localizer>
              <input
                type="submit"
                className={className('aaw-button', {
                  'aaw--hidden': this.state.isProcessing,
                })}
                name="save"
                value={<Text id="signupTab.save">Save</Text>}
                role="button"
              />
            </Localizer>
            {aawLoader}
          </div>
        </div>
      </form>
    );
  }

  render(props, state) {
    return this.renderAdditionalWeChatFields(props, state);
  }
}
