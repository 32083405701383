import fetch from 'unfetch';
import { isOTPEnabled } from '../config/feature.flags';

export default (
  username,
  password,
  dvToken,
  clientId,
  apiKey,
  gaClientId,
  dialingCode,
  phoneNumber,
  otp
) => {
  let payload = {
    username,
    password,
    dvToken,
  };

  payload = isOTPEnabled('login')
    ? {
        ...payload,
        ...{
          optIn2FA: true,
          dialingCode,
          phoneNumber,
          otp,
        },
      }
    : payload;

  return fetch(
    // Deprecated: switch this back to /auth to stop supporting cookie.
    `${process.env.API_GW_URL}/sso/v2/authorization/by-credentials?clientId=${clientId}&gaClientId=${gaClientId}`,
    {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify(payload),
    }
  ).then((response) => {
    return response.json().then((data) => {
      return {
        status: response.status,
        data,
      };
    });
  });
};
