import { h, Component } from 'preact';
import { Localizer, Text } from 'preact-i18n';
import className from 'classnames';

import Errors from '../errors';
import validator from '../../helpers/validators';
import { getGeoData } from '../../helpers';

import TextField from '../common/textField';
import PasswordField from '../common/passwordField';

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: props.email,
      password: props.password,
      error: props.error,
      usernameError: false,
      passwordError: false,
      isErrorFocused: false,
      isLoginLinkRequired: false,
      customerSupportRegister: props.customerSupportRegister,
      isActivationLinkRequired: props.isActivationLinkRequired,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({ error: nextProps.error });
    }
    if (nextProps.email) {
      this.setState({ username: nextProps.email });
    }
  }

  handleChangeUsername = (username) => {
    this.setState({ username: username.toLowerCase() });
  };

  handleChangePassword = (password) => {
    this.setState({ password });
  };

  handleErrorMessageBlur = () => {
    this.setState({ isErrorFocused: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      error: '',
      usernameError: false,
      passwordError: false,
      customerSupportRegister: false,
      isActivationLinkRequired: false,
    });
    const { username, password } = this.state;

    if (!validator.isEmail(username)) {
      this.setState({
        error: 'errors.invalidEmail',
        usernameError: true,
        isErrorFocused: true,
      });
      return;
    }
    if (!validator.isPassword(password)) {
      this.setState({
        error: 'errors.incorrectPasswordSignup',
        passwordError: true,
        isErrorFocused: true,
      });
      // Example for "Email exist" error when link goes to login tab with prefilled email
      // this.setState({error: 'errors.emailExists', passwordError: true, isErrorFocused: true, isLoginLinkRequired: true});
      return;
    }

    const geoData = getGeoData(document.cookie);
    const params = {
      username,
      password,
      clientId: this.props.clientId,
      country: geoData.country,
      nationality: geoData.nationality,
      gaClientId: this.props.gaClientId,
    };

    this.props.onSubmit(params);
  };

  render(props, state) {
    return (
      <div
        id="aaw-signup-tab"
        className={className({
          'aaw-tab-container--vertical': props.hasSocialLogin,
        })}
        role="tabpanel"
        aria-labelledby="aaw-signup"
      >
        <form onSubmit={this.handleSubmit}>
          <Errors
            error={state.error}
            activationLink={state.isActivationLinkRequired}
            customerSupportRegister={state.customerSupportRegister}
            linkFunction={this.resendActivationLink}
            isFocused={state.isErrorFocused}
            email={props.email}
            onBlur={this.handleErrorMessageBlur}
          />
          <div
            className={className({
              'aaw-row': props.isHorizontal && !props.hasSocialLogin,
            })}
          >
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6':
                  props.isHorizontal && !props.hasSocialLogin,
              })}
            >
              <TextField
                textLabel={<Text id="email">Email</Text>}
                error={state.usernameError}
                tab="signup"
                onValueChange={this.handleChangeUsername}
                defaultValue={state.username}
                maxLength={64}
              />
            </div>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m6':
                  props.isHorizontal && !props.hasSocialLogin,
              })}
            >
              <PasswordField
                error={state.passwordError}
                tab="signup"
                defaultValue={state.password}
                onPasswordChange={this.handleChangePassword}
                maxLength={16}
              />
            </div>
          </div>
          <div
            className={className({
              'aaw-row aaw-align--vertical':
                props.isHorizontal && !props.hasSocialLogin,
            })}
          >
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m7': props.isHorizontal,
                'aaw-top-padding': !props.isHorizontal,
                'aaw--hidden': props.hasSocialLogin,
              })}
            >
              <label className="aaw-agree-terms" htmlFor="agree">
                <Text id="signupTab.iAgree" />
                <Localizer>
                  <a
                    href={
                      <Text id="signupTab.termsAndConditionsLink">
                        https://www.airasia.com/my/en/about-us/our-policies.page#BIGLoyalty
                      </Text>
                    }
                    target="_blank"
                    id="termsConditionsLink"
                    aria-label={
                      <Text id="signupTab.termsAndConditions">
                        Terms and conditions, Privacy Policy and Terms of use
                      </Text>
                    }
                  >
                    <Text id="signupTab.termsAndConditions">
                      Terms and conditions, Privacy Policy and Terms of use
                    </Text>
                  </a>
                </Localizer>
                <Text id="signupTab.termsAndConditionsEnd" />
              </label>
            </div>
            <div
              className={className({
                'aaw-col aaw-s12 aaw-m5 aaw-el--top-padding':
                  props.isHorizontal && !props.hasSocialLogin,
                'aaw-top-padding': props.hasSocialLogin || !props.isHorizontal,
              })}
            >
              <Localizer>
                <input
                  type="submit"
                  id="signupButton"
                  className="aaw-button"
                  name="signup"
                  value={
                    <Text id="signupTab.signupForFree">Sign up for free</Text>
                  }
                  role="button"
                />
              </Localizer>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
