import LocalStorageClient from './localStorageClient';

export default {
  TOKEN_KEY: 'aa_tokens',

  /**
   * @type LocalStorageClient
   */
  client: null,

  init() {
    if (!this.client) {
      this.client = new LocalStorageClient(
        `${process.env.STORAGE_HUB_URL}?origin=${location.origin}`
      );

      return this.client
        .onConnect()
        .catch((err) => {
          console.log(err);
          console.log('SSO: Falling back to use native localStorage');

          this.client.fallback = true;
          const gtmDataLayer = window.gtmDataLayer || [];
          gtmDataLayer.push({
            event: 'trackEventNoEcommerce',
            'eventDetails.category': 'SSO Widget',
            'eventDetails.action': 'initialize',
            'eventDetails.label': 'unable to init hub',
          });
        })
        .then(() => this.client);
    }

    return Promise.resolve(this.client);
  },
  get() {
    return this.init()
      .then((client) => client.getJSON(this.TOKEN_KEY))
      .then((tokens) => tokens || {});
  },
  set(tokens) {
    return this.init().then((client) => client.setJSON(this.TOKEN_KEY, tokens));
  },
  del() {
    return this.init().then((client) => client.del(this.TOKEN_KEY));
  },
};
