import { h, Component } from 'preact';
import className from 'classnames';
import { Text } from 'preact-i18n';

export default class Tab extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.props.onClick.bind(this, this.props.name);
  }

  componentDidUpdate() {
    if (this.props.isActive) {
      this.tabRef.focus();
    }
  }

  render(props) {
    return (
      <div
        id={`aaw-${props.name}`}
        className={className(
          `aaw-tabs__${props.name + '--' + props.orientation}`,
          { [`aaw-tab__active--${props.orientation}`]: props.isActive }
        )}
        onClick={this.onClick}
        onKeyUp={props.onKeyUp}
        role="tab"
        tabIndex={0}
        aria-selected={String(props.isActive)}
        aria-controls={`aaw-${props.name}`}
        ref={(el) => (this.tabRef = el)}
      >
        <Text id={`tabs.${props.name}`}>{props.name}</Text>
      </div>
    );
  }
}
