/*
 * The feature is enabled for any origin if that's in whitelist. Also it's enabled for an
 * origin if that's not in whitelist but not in blacklist either.
 * An origin must not co-exist both in blacklist and whitelist of same feature.
 */
const featureFlags = {
  features: {
    otp: {
      variations: {
        login: {
          whitelist: [],
          blacklist: [],
        },
      },
    },
    wechat: {
      variations: {
        mobile: {
          whitelist: [
            'http://demo.rokki.com', // aawifi staging
            'https://wifi.airasia.com', // aawifi production
          ],
          blacklist: [],
        },
      },
    },
  },
};

function isOTPEnabled(variation) {
  if (!['login', 'signup'].includes(variation)) {
    throw new Error(
      `featureFlag: invalid variation ${variation} provided for feature otp.`
    );
  }

  return _isFeatureEnabled('otp', variation);
}

function isWechatEnabled(variation) {
  if (!['mobile', 'desktop'].includes(variation)) {
    throw new Error(
      `featureFlag: invalid variation ${variation} provided for feature wechat.`
    );
  }

  return _isFeatureEnabled('wechat', variation);
}

/*
 * Returns true, if feature variation is whitelisted for current origin
 * or not blacklisted and the variation is not whitelisted for any other origins.
 * Returns false, if feature variation is blacklisted for current origin.
 */
function _isFeatureEnabled(feature, variation) {
  const ff = featureFlags.features[feature];
  const ffVariation = ff.variations[variation];
  const origin = window.origin;

  return (
    !ffVariation ||
    ffVariation.whitelist.includes(origin) ||
    (!ffVariation.blacklist.includes(origin) && !ffVariation.whitelist.length)
  );
}

export { isOTPEnabled, isWechatEnabled };
