import { isOTPEnabled } from '../config/feature.flags';
import { getCookieValue, getGeoData, getLanguage, getOrigin } from '../helpers';
import authToken from '../helpers/authToken';
import nativeAppCommunicator from '../helpers/nativeAppCommunicator';

const POPUP_INTERVAL_TIMEOUT = 150; // in millieseconds

function getPopupOptions() {
  const windowOptions =
    'scrollbars=1,resizable=1,toolbar=0,location=0,dialog=1';
  const width = 750;
  const height = 520;
  const { height: winHeight, width: winWidth } = screen;
  const left = Math.round(winWidth / 2 - width / 2);
  const top = winHeight > height ? Math.round(winHeight / 2 - height / 2) : 0;
  return `${windowOptions},width=${width},height=${height},left=${left},top=${top}`;
}

function getUrlSearchParamsAsObject(search) {
  const parameters = new URLSearchParams(search);
  const data = {};

  for (const [key, value] of parameters.entries()) {
    data[key] = value;
  }

  return data;
}

function sendGtmData(authType, networkType, { email, loyaltyId }) {
  const gtmDataLayer = window.gtmDataLayer || [];

  switch (authType) {
    case 'login': {
      gtmDataLayer.push({
        event: 'login',
        loginMethod: networkType,
        loyaltyId,
      });
      break;
    }
    case 'signup': {
      if (networkType !== 'wechat') {
        gtmDataLayer.push({
          event: 'signupSuccess',
          signupMethod: networkType,
          email,
        });
      }
      break;
    }
  }
}

export default function (
  networkType,
  { authType, clientId, gaClientId, hasOtp, redirectUrl = getOrigin() } = {}
) {
  if (!networkType || !authType || !clientId || !redirectUrl) {
    return Promise.reject(new Error('insufficient settings'));
  }

  const otpEnabled = isOTPEnabled('login');
  const parameters = {
    clientId,
    country: getGeoData(document.cookie).country,
    gaClientId: getCookieValue(gaClientId),
    language: getLanguage(),
    optIn2FA: otpEnabled,
    resultRedirectUrl: redirectUrl,
  };

  // remove falsy value from parameters to ensure no empty query parameter is added
  Object.keys(parameters).forEach(
    (key) => !parameters[key] && delete parameters[key]
  );

  const endpoint = `${process.env.API_GW_URL}/auth/v2/authorization/social/${networkType}`;
  const action = hasOtp && otpEnabled ? '/otp' : '';
  const url = `${endpoint}${action}?${new URLSearchParams(
    parameters
  ).toString()}`;
  const popup = window.open(url, 'popup', getPopupOptions());
  return new Promise((resolve, reject) => {
    const responseInterval = setInterval(() => {
      if (popup.closed) {
        clearInterval(responseInterval);
        return;
      }

      try {
        const popupUrl = popup.location.href;

        if (popupUrl.indexOf(redirectUrl) !== -1) {
          clearInterval(responseInterval);
          const response = getUrlSearchParamsAsObject(popup.location.search);
          const { error } = response;

          if (error) {
            reject(error);
            return;
          }

          const {
            // authentication details
            accessToken,
            refreshToken,
            userId,
            loyaltyId,
            // form details
            otpForm,
            updateForm,
            // user details
            email,
          } = response;

          if (!otpForm && accessToken) {
            if (updateForm === 'false') {
              const tokens = { accessToken, refreshToken, userId, loyaltyId };
              authToken
                .set(tokens)
                .then(() => nativeAppCommunicator.send(tokens, 'login'));
            }

            sendGtmData(authType, networkType, { email, loyaltyId });
          }

          resolve({
            ...response,
            network: networkType,
            username: email,
          });
          popup.close();
        }
      } catch (e) {
        console.error(e);
      }
    }, POPUP_INTERVAL_TIMEOUT);
  });
}
