import fetch from 'unfetch';

export default (userId, accessToken, clientId, apiKey, gaClientId) =>
  fetch(`${process.env.API_GW_URL}/um/v2/users/${userId}`, {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: accessToken,
      'x-api-key': apiKey,
      'x-aa-client-id': clientId,
      'x-ga-client-id': gaClientId,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  });
