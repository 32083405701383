import fetch from 'unfetch';

export default (params, apiKey) =>
  fetch(
    `${process.env.API_GW_URL}/auth/v2/authorization/social/wechat/callback/?state=${params.state}&openid=${params.openid}&username=${params.emailAddress}&lastName=${params.lastName}&firstName=${params.firstName}&gender=${params.gender}`,
    {
      method: 'get',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    }
  ).then((response) => {
    return response.json();
  });
